import React from "react";
import style from "./index.module.scss";

const Strategy = ({ strategy, policy, study }) => (
  <>
    <div className={style.chart_container}>
      <div className={style.row}>
        <div className={style.content}>
          <div className={style.stat_container}>
            <div className={style.basic_stat}>{parseInt(policy) + "%"}</div>
            <div className={style.basic_stat_text}>
              Of companies have an <b>Anti-Harassment Policy</b>
            </div>
          </div>
        </div>

        <div className={style.content}>
          <div className={style.stat_container}>
            <div className={style.basic_stat}>{parseInt(strategy) + "%"}</div>
            <div className={style.basic_stat_text}>
              Of companies have a <b>DEI Strategy</b>
            </div>
          </div>
        </div>
        <div className={style.content}>
          <div className={style.stat_container}>
            <div className={style.basic_stat}>{parseInt(study) + "%"}</div>
            <div className={style.basic_stat_text}>
              Of companies conducted a <b>Pay Equity Study</b> in the past three
              years
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Strategy;
