import React from "react";
import Select from "components/select";
import { find, map } from "lodash";
import { HEALTHCARE } from "./data";
import Bar from "components/barV2";
import { VictoryChart, VictoryTooltip, VictoryPie, VictoryAxis } from "victory";
import style from "./index.module.scss";
import sharedStyle from "charts/shared.module.scss";

const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0
});
const toDollars = number => numberFormatter.format(number);

const DATA_MAP = {
  [HEALTHCARE["Seed-A"].name]: HEALTHCARE["Seed-A"],
  [HEALTHCARE["B-C"].name]: HEALTHCARE["B-C"],
  [HEALTHCARE["D+"].name]: HEALTHCARE["D+"]
};

const filterOptions = map(DATA_MAP, (_, key) => ({ label: key, value: key }));
const getDefaultOption = () =>
  find(filterOptions, { value: HEALTHCARE["Seed-A"].name });

const List = ({ data }) => (
  <div className={style.list_container}>
    {data.map(row => (
      <div className={style.list_row} key={row + "bp2"}>
        {row}
      </div>
    ))}
  </div>
);

class BenefitsProvidersChart extends React.Component {
  state = {
    selectedChart: HEALTHCARE["Seed-A"]
  };

  handleChange = option =>
    this.setState({ selectedChart: DATA_MAP[option.value] });

  render() {
    const {
      selectedChart: {
        name,
        peo,
        peoVbroker,
        medicalCoverage,
        medicalBreakdown,
        hsa,
        plansOffered
      }
    } = this.state;

    return (
      <div className={style.container}>
        <div className={style.filter}>
          {" "}
          <div className={style.select_container}>
            <Select
              options={filterOptions}
              defaultValue={getDefaultOption(name)}
              onChange={this.handleChange}
            />
          </div>
        </div>

        <div className={style.chart_container}>
          <div className={style.row}>
            <div className={style.content}>
              <div className="chart-name-list">
                <span className="hover_text">
                  PEOs
                  <span className="tooltip" style={{ width: `155px` }}>
                    We recommend <b>Justworks</b>.
                  </span>{" "}
                </span>
              </div>
              <List data={peo} />
            </div>

            <div className={style.content}>
              <div className="chart-name">
                PEO vs.{" "}
                <span className="hover_text">
                  Broker
                  <span className="tooltip">
                    We recommend <b>Nava</b> for US-based companies and{" "}
                    <b>Pipo Saúde</b> for LATAM-based companies.
                  </span>{" "}
                </span>
              </div>
              <div className={style.pie_flex}>
                <div className={style.pie_container}>
                  <VictoryChart height={180} padding={{ left: 0 }}>
                    <VictoryPie
                      data={peoVbroker}
                      // padding={125}
                      radius={80}
                      labelComponent={
                        <VictoryTooltip
                          pointerLength={0}
                          cornerRadius={5}
                          flyoutStyle={{
                            fill:
                              "linear-gradient(180deg, rgba(16, 16, 16, 0.76) 0%, rgba(33, 33, 33, 0.6612) 100%);",
                            color: "white"
                          }}
                        />
                      }
                      innerRadius={45}
                      style={{
                        labels: {
                          fontSize: 12,
                          fontWeight: "normal",
                          fill: "white",
                          fontFamily: "'Helvetica Neue', Helvetica, sans-serif"
                        },
                        data: { fill: ({ datum }) => datum.color }
                      }}
                      animate={{
                        duration: 2000,
                        onLoad: { duration: 2000 }
                      }}
                    />
                    <VictoryAxis
                      style={{
                        axis: { stroke: "transparent" },
                        ticks: { stroke: "transparent" },
                        tickLabels: { fill: "transparent" }
                      }}
                    />
                  </VictoryChart>
                </div>
                <div className={style.legends}>
                  {peoVbroker.map(row => (
                    <div key={row.x + "bp3"}>
                      <span
                        className={style.legend_box}
                        style={{ backgroundColor: row.color }}
                      ></span>
                      <span className={style.legend_text}>{row.x}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className={style.row}>
            {/* <div className={style.content}>
              <div className="chart-name-list">Brokers</div>
              <List data={brokers} />
            </div> */}
            <div className={style.content}>
              <div className="chart-name-list">Insurance Providers</div>
              <div className={style.list_container}>
                <div className={style.list_row}>
                  <span className="hover_text1">
                    Aetna
                    <span className="tooltip">
                      Most popular among Seed-Series C companies
                    </span>
                  </span>
                </div>
                <div className={style.list_row}>Cigna</div>
                <div className={style.list_row}>Blue Cross/Blue Shield</div>
                <div className={style.list_row}>Kaiser Permanente</div>
                <div className={style.list_row}>United Healthcare</div>
                <div className={style.list_row}>Sulamerica</div>
                <div className={style.list_row}>Anthem Blue Cross</div>
              </div>
            </div>

            <div className={style.content}>
              <div className={style.stat_container}>
                <div className={style.basic_stat}>{plansOffered}</div>
                <div className={style.basic_stat_text}>
                  Avg Number of <b>Healthcare Plans</b> offered to employees
                </div>
              </div>
              <div className={style.stat_container}>
                <div className={style.basic_stat}>
                  {parseInt(hsa * 100) + "%"}
                </div>
                <div className={style.basic_stat_text}>
                  Of companies offer plans with <b>HSA Compatibility</b>
                </div>
              </div>
            </div>
          </div>

          {/* <div className={style.row}>
            
          <div className={style.content}>
              <div className="chart-name-list"><span className="hover_text">Insurance Providers<span className="tooltip">Most popular among Seed-Series C companies</span> </span></div>
              <List data={healthcareProviders} />
            </div>

            <div className={style.content}>
              <div className={style.stat_container}>
                <div className={style.basic_stat}>{parseInt(hsa*100)+'%'}</div> 
                <div className={style.basic_stat_text}>Of companies offer plans with <b>HSA Compatibility</b></div>              
              </div>
            </div>
          </div> */}

          <div className={style.row}>
            <div className={style.content}>
              <div className="chart-name-list">
                Average out-of-pocket benefits cost per month
              </div>
              <div className={style.table}>
                <div className={style.header_row}>
                  <div className={style.column}> Coverage Group </div>
                  <div className={style.column2}> Employee Cost </div>
                  <div className={style.column3}> Employer Cost </div>
                </div>
                {medicalBreakdown.map(row => (
                  <div className={style.table_row} key={row.coverage + "bp1"}>
                    <div className={style.column}> {row.coverage} </div>
                    <div className={style.column2}>
                      {" "}
                      <span className={sharedStyle.toolbox}>
                        {toDollars(parseInt(row.employee))}{" "}
                        <span
                          className={sharedStyle.tooltip3}
                          style={{ width: `110px` }}
                        >
                          {row.employee_hover}
                          <br />
                          {row.employee_hover2}
                          <br />
                          {row.employee_hover3}
                        </span>
                      </span>{" "}
                    </div>
                    <div className={style.column3}>
                      {" "}
                      <span className={sharedStyle.toolbox}>
                        {toDollars(parseInt(row.employer))}{" "}
                        <span
                          className={sharedStyle.tooltip3}
                          style={{ width: `110px` }}
                        >
                          {row.employer_hover}
                          <br />
                          {row.employer_hover2}
                          <br />
                          {row.employer_hover3}
                        </span>
                      </span>{" "}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className={style.content}>
              <div className="chart-name-list-right">
                Percent covered by employer
              </div>
              <div>
                <div className={style.double_bar_container}>
                  <Bar
                    value={medicalCoverage.employees.avg * 100}
                    group={"Employees"}
                    max={100}
                    label={`Avg ${Math.round(
                      medicalCoverage.employees.avg * 100
                    )}%`}
                    isLabelInside
                    hover={`${Math.round(
                      medicalCoverage.employees.min * 100
                    )}-${Math.round(
                      medicalCoverage.employees.max * 100
                    )}% coverage offered to employees`}
                  />
                  <Bar
                    value={medicalCoverage.dependents.avg * 100}
                    group={"Dependents"}
                    max={100}
                    label={`Avg ${Math.round(
                      medicalCoverage.dependents.avg * 100
                    )}%`}
                    isLabelInside
                    hover={`${Math.round(
                      medicalCoverage.dependents.min * 100
                    )}-${Math.round(
                      medicalCoverage.dependents.max * 100
                    )}% coverage offered to dependents`}
                    light
                  />
                </div>
              </div>
              <p className={style.coverage_paragraph}>
                When selecting benefits, we recommend being highly attentive to
                the dollar amount employees are asked to pay per month for
                healthcare, especially those with dependents and families.
                Employees being asked to pay a prohibitive dollar amount per
                month is the one, rare instance when we’ve seen benefits bar an
                employee from accepting an offer.{" "}
              </p>
            </div>
          </div>

          <div className={style.row}>
            <div className={style.content}>
              <div className="chart-name-list">
                Other Health and Wellness Benefits
              </div>
              <div className={style.list_container}>
                <div className={style.list_row}>
                  <span className="hover_text1">
                    Dental + Vision
                    <span className="tooltip">
                      Nearly 100% of companies provide complete coverage.
                    </span>
                  </span>
                </div>
                <div className={style.list_row}>
                  <span className="hover_text1">
                    Direct Primary Care
                    <span className="tooltip">
                      <b>Common Providers:</b> One Medical
                    </span>
                  </span>
                </div>
                <div className={style.list_row}>
                  <span className="hover_text1">
                    Mental Health
                    <span className="tooltip">
                      <b>Common Providers:</b> TalkSpace, Ginger, Spring Health
                    </span>
                  </span>
                </div>
                <div className={style.list_row}>Fertility Care</div>
                <div className={style.list_row}>Fitness Stipends</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BenefitsProvidersChart;
