import React from "react";
import ReactSelect from "react-select";

const styles = {
  control: (provided, state) => ({
    ...provided,
    border: "1px solid #464646",
    borderRadius: '5px',
    backgroundColor: '#232323',
    color: 'pink'
  }),
  menu: (provided, state) => ({
    ...provided,
    border: "1px solid #464646",
    borderRadius: '5px',
    backgroundColor: '#232323',
    color: 'white'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'white'
  }),
  indicatorsContainer: () => {},
  indicatorSeparator: () => {}
};

const Select = props => <ReactSelect {...props} styles={styles} 
theme={(theme) => ({
  ...theme,
  colors: {
  ...theme.colors,
    text: 'white',
    primary25: '#212121',
    primary: '#101010',
  },
})}
/>;

export default Select;
