import React, { useState, useEffect } from "react";
import style from "charts/shared.module.scss";
import StackedBar from "components/stackedBar";
import * as d3 from "d3";
import data from "../thrive_data.csv";
import { Waypoint } from "react-waypoint";

const ReturnToWork = () => {
  const [Data, setData] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    d3.csv(data).then(function(data) {
      var unsorted = [];

      var rtw_series = data.filter(function(d) {
        return d.Q45 !== "";
      });

      var total = d3.rollup(
        rtw_series,
        v => v.length,
        d => d.Q2
      );

      function compare(a, b) {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      }

      d3.rollup(
        rtw_series,
        v => v.length,
        d => d.Q2,
        d => d.Q45
      ).forEach(function(value, key) {
        var rtw_values = [];
        var order = { "Seed-A": 1, "B-C": 2, "D+": 3 };
        var label = {
          "Seed-A": "Seed - Series A",
          "B-C": "Series B-C",
          "D+": "Series D+"
        };
        var legend = {
          "Hybrid - Mostly In-Person": 0,
          Hybrid: 1,
          "Hybrid - Mostly Remote": 2,
          "Fully Remote": 3
        };
        var colors = ["#E0E3E7", "#91AFD8", "#435B9B", "#16213D"];
        var text = ["#282828", "#282828", "white", "white"];
        // var i = 0
        value.forEach(function(value, k) {
          var pct = value / total.get(key);
          rtw_values.push({
            order: legend[k],
            value: pct,
            fill: colors[legend[k]],
            text: text[legend[k]]
          });
          // i++
        });
        unsorted.push({
          order: order[key],
          name: label[key],
          data: rtw_values.sort(compare)
        });
      });

      var Data = unsorted.sort(compare);

      setData(Data);

      console.log(Data);
    });
  }, []);

  let comp;
  if (load) {
    comp = (
      <>
        {Data.map(row => (
          <div className={style.row} key={row.name + "rtw"}>
            <div className={style.left}>
              <span>{row.name}</span>
            </div>
            <div className={style.right}>
              <StackedBar data={row.data} />
            </div>
          </div>
        ))}
      </>
    );
  } else {
    comp = (
      <>
        {Data.map(row => (
          <div className={style.row} key={row.name + "rtw"}>
            <div className={style.left}>
              <span>{row.name}</span>
            </div>
            <div className={style.right}>
              <StackedBar data={[{ value: 0 }]} />
            </div>
          </div>
        ))}
      </>
    );
  }

  return (
    <Waypoint onEnter={() => setLoad(true)} onLeave={() => setLoad(false)}>
      <div className={style.container}>
        <h4 className={style.chart_title}>Return to work approach</h4>
        <div className={style.row}>
          <div className={style.left__header_row}>
            <div className={style.title__sm}>Stage</div>
          </div>
          <div className={style.right__header_row}>
            <div className={style.title}>% of companies</div>
            <div className={style.legend_group}>
              <div className={style.legend_container}>
                <div className={style.legend_box_light}></div>
                <span className={style.legend_text}>
                  Hybrid - Mostly In-Person
                </span>
              </div>
              <div className={style.legend_container}>
                <div className={style.legend_box_2}></div>
                <span className={style.legend_text}>Hybrid</span>
              </div>
              <div className={style.legend_container}>
                <div className={style.legend_box_3}></div>
                <span className={style.legend_text}>
                  Hybrid - Mostly Remote
                </span>
              </div>
              <div className={style.legend_container}>
                <div className={style.legend_box_4}></div>
                <span className={style.legend_text}>Fully Remote</span>
              </div>
            </div>
          </div>
        </div>

        {comp}
        <div className={style.axis}>
          <div className={style.axis_item}>0%</div>
          <div className={style.axis_item}>50%</div>
          <div className={style.axis_item}>100%</div>
        </div>
      </div>
    </Waypoint>
  );
};

export default ReturnToWork;
