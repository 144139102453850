import React, { useState } from "react";
import { isFunction } from "lodash";
import cn from "classnames";
import style from "./index.module.scss";

const OutsideLabel = ({ label, on }) =>
  label && on ? (
    <span className={cn(style.bar_label)}>
      {isFunction(label) ? label() : label}
    </span>
  ) : null;

const InsideLabel = ({ label, on }) =>
  label && on ? (
    <span className={cn(style.bar_label, style.bar_label__inside)}>
      {isFunction(label) ? label() : label}
    </span>
  ) : null;

// max = max doamin, scale
// value = how much to fill
const Bar = ({
  value,
  max = 100,
  label,
  hover,
  background,
  light = false,
  isLabelInside = false,
  group
}) => {
  // how much to fill
  const [isHover, toggleHover] = useState(false);
  const width = (value / max) * 100;

  return (
    <div className={style.group}>
      <div className={style.group_label}>{group}</div>
    <div
      className={cn(style.bar_container, {
        [style.bar_background]: background
      })}
    >
      <div
        className={cn(style.bar, { [style.bar__light]: light })}
        style={{ width: `${width}%` }}
        onMouseEnter={() => toggleHover(true)}
        onMouseLeave={() => toggleHover(false)}
      >
        {isHover && (
          <div className={style.bar_hover}>
            {isFunction(hover) ? hover() : hover}
          </div>
        )}
        <InsideLabel label={label} on={isLabelInside} />
      </div>
      <OutsideLabel label={label} on={!isLabelInside} />
    </div>
    </div>
  );
};

export default Bar;
