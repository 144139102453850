import React from "react";
import TargetComp from "./targetComp";
import AnnualSpend from "./annualSpend";
import HRSpend from "./hrSpend";
import style from "./index.module.scss";

const BudgetCharts = () => (
  <div className={style.container}>
    <div className={style.chart_container}>
      <AnnualSpend />
    </div>
    <div className={style.chart_container}>
      <HRSpend />
    </div>
    <div className={style.chart_container}>
      <TargetComp />
    </div>
  </div>
);

export { BudgetCharts };
