import React from "react";
import Select from "components/select";
import Bar from "components/bar";
import { find, map } from "lodash";
import { HEADCOUNT } from "./data";
import style from "./index.module.scss";
import { Waypoint } from "react-waypoint";

const CHART_MAP = {
  [HEADCOUNT["eCommerce/DTC"].x]: HEADCOUNT["eCommerce/DTC"],
  [HEADCOUNT.Fintech.x]: HEADCOUNT.Fintech,
  [HEADCOUNT.Healthtech.x]: HEADCOUNT.Healthtech,
  [HEADCOUNT.Marketplace.x]: HEADCOUNT.Marketplace,
  [HEADCOUNT.SaaS.x]: HEADCOUNT.SaaS
};
console.log(CHART_MAP);

const filterOptions = map(CHART_MAP, (chart, _) => ({
  label: chart.x,
  value: chart.x
}));

const getDefaultOption = ({ x }) => find(filterOptions, { value: x });

class HeadcountChart extends React.Component {
  state = {
    selectedChart: HEADCOUNT["eCommerce/DTC"],
    load: false
  };

  handleChange = option =>
    this.setState({ selectedChart: CHART_MAP[option.value] });

  handleWaypoint = option => this.setState({ load: !this.state.load });

  render() {
    const { selectedChart } = this.state;

    let comp;

    if (this.state.load) {
      comp = (
        <>
          {selectedChart.y.map(row => (
            // {Data.y.map(row => (
            <div className={style.row} key={row.type + "head"}>
              <div className={style.left}>
                <span className={style.tool}>
                  {row.type}
                  <span className={style.tooltip}>{row.pct_cat}%</span>
                </span>
              </div>
              <div className={style.right}>
                <Bar
                  value={row.roles}
                  groupsize={row.roles.length}
                  labelText={row.roles}
                />
              </div>
            </div>
          ))}
        </>
      );
    } else {
      comp = (
        <>
          {selectedChart.y.map(row => (
            // {Data.y.map(row => (
            <div className={style.row} key={row.type + "head"}>
              <div className={style.left}>
                <span className={style.tool}>
                  {row.type}
                  <span className={style.tooltip}>{row.pct_cat}%</span>
                </span>
              </div>
              <div className={style.right}>
                <Bar
                  value={Array(row.roles.length).fill({ x: "none", y: 0 })}
                  groupsize={row.roles.length}
                  labelText={row.roles}
                />
              </div>
            </div>
          ))}
        </>
      );
    }

    return (
      <Waypoint onEnter={this.handleWaypoint} onLeave={this.handleWaypoint}>
        <div className={style.container}>
          <div className={style.select_row}>
            <div className={style.select_container}>
              <Select
                options={filterOptions}
                defaultValue={getDefaultOption(selectedChart)}
                onChange={this.handleChange}
              />
            </div>
            <div className={style.stat_container}>
              <div className={style.basic_stat}>
                {this.state.selectedChart.ratio}
              </div>
              <div className={style.basic_stat_text}>EPD Ratio</div>
            </div>
          </div>
          <div className={style.header_row}>
            <div className={style.left__header_row}>
              <div className={style.title__sm}>Function</div>
            </div>
            <div className={style.right__header_row}>
              <div className={style.title}>Avg % of total FTEs</div>
            </div>
          </div>

          {comp}
        </div>
      </Waypoint>
    );
  }
}

export default HeadcountChart;
