import React, { useState, useEffect } from "react";
import StackedBar from "components/stackedBar";
import style from "charts/shared.module.scss";
import * as d3 from "d3";
import data from "../thrive_data.csv";
import { Waypoint } from "react-waypoint";

const PTOChart = () => {
  const [Data, setData] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    d3.csv(data).then(function(data) {
      var unsorted = [];

      var filtered = data.filter(function(d) {
        return d.Q77 !== "";
      });

      var unlimited = filtered.filter(function(d) {
        return d.Q77 === "999";
      });
      var unl = d3.rollup(
        unlimited,
        v => v.length,
        d => d.Q2
      );

      d3.rollup(
        filtered,
        v => v.length,
        d => d.Q2
      ).forEach(function(value, key) {
        var values = [];
        if (unl.get(key)) {
          values.push({
            value: unl.get(key) / value,
            fill: "#E0E3E7",
            text: "#282828"
          });
          values.push({
            value: (value - unl.get(key)) / value,
            fill: "#4E66A5"
          });
        } else {
          values.push({ value: 0, fill: "#E0E3E7", text: "#282828" });
          values.push({ value: 1, fill: "#4E66A5" });
        }
        var order = { "Seed-A": 1, "B-C": 2, "D+": 3 };
        var label = {
          "Seed-A": "Seed - Series A",
          "B-C": "Series B-C",
          "D+": "Series D+"
        };
        unsorted.push({ order: order[key], x: label[key], y: values });
      });

      function compare(a, b) {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      }

      var Data = unsorted.sort(compare);

      setData(Data);

      console.log(Data);
    });
  }, []);

  let comp;
  if (load) {
    comp = (
      <>
        {Data.map(row => (
          <div className={style.row} key={row.x + "ptounl"}>
            <div className={style.left}>
              <span>{row.x}</span>
            </div>
            <div className={style.right}>
              <StackedBar data={row.y} />
            </div>
          </div>
        ))}
      </>
    );
  } else {
    comp = (
      <>
        {Data.map(row => (
          <div className={style.row} key={row.x + "ptounl"}>
            <div className={style.left}>
              <span>{row.x}</span>
            </div>
            <div className={style.right}>
              <StackedBar data={[{ value: 0 }]} />
            </div>
          </div>
        ))}
      </>
    );
  }

  return (
    <Waypoint onEnter={() => setLoad(true)} onLeave={() => setLoad(false)}>
      <div className={style.container}>
        <h4 className={style.chart_title}>Unlimited vs. defined PTO</h4>
        <div className={style.row}>
          <div className={style.left__header_row}>
            <div className={style.title__sm}>Stage</div>
          </div>
          <div className={style.right__header_row}>
            <div className={style.title}>% of companies</div>
            <div className={style.legend_group}>
              <div className={style.legend_container}>
                <div className={style.legend_box_light}></div>
                <span className={style.legend_text}>Unlimited</span>
              </div>
              <div className={style.legend_container}>
                <div className={style.legend_box_dark}></div>
                <span className={style.legend_text}>Defined</span>
              </div>
            </div>
          </div>
        </div>

        {comp}
        <div className={style.axis}>
          <div className={style.axis_item}>0%</div>
          <div className={style.axis_item}>50%</div>
          <div className={style.axis_item}>100%</div>
        </div>
      </div>
    </Waypoint>
  );
};

export default PTOChart;
