import React, { useState, useEffect } from "react";
import style from "charts/shared.module.scss";
import Box from "components/box";
import * as d3 from "d3";
import data from "../thrive_data.csv";
import { Waypoint } from "react-waypoint";

const HireTime = () => {
  const [Data, setData] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    d3.csv(data).then(function(data) {
      var unsorted = [];
      var temp = [];

      var filtered = data.filter(function(d) {
        return (d.Q17 !== "" || d.Q18 !== "") && d.Q2 !== "Seed-A";
      });

      d3.group(filtered, d => d.Q2).forEach(function(value, key) {
        temp.push({ x: key, y: value });
      });

      temp.forEach(function(value) {
        var values = [];
        var values2 = [];
        var order = { "Seed-A": 1, "B-C": 2, "D+": 3 };
        var label = {
          "Seed-A": "Seed - Series A",
          "B-C": "Series B-C",
          "D+": "Series D+"
        };
        value.y.forEach(function(val) {
          if (val.Q17 !== "") {
            values.push(parseFloat(val.Q17));
          }
          if (val.Q18 !== "") {
            values2.push(parseFloat(val.Q18));
          }
        });
        unsorted.push({
          order: order[value.x],
          x: label[value.x],
          y: values2,
          z: values,
          hover_avg: "Avg " + Math.round(d3.mean(values2)) + " weeks",
          hover_range:
            "Range " +
            Math.round(d3.min(values2)) +
            "-" +
            Math.round(d3.max(values2)) +
            " weeks",
          hover_avg2: "Avg " + Math.round(d3.mean(values)) + " weeks",
          hover_range2:
            "Range " +
            Math.round(d3.min(values)) +
            "-" +
            Math.round(d3.max(values)) +
            " weeks"
        });
      });

      function compare(a, b) {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      }

      var Data = unsorted.sort(compare);

      // setData(Data);
      var dummy_data = JSON.parse(JSON.stringify(Data));

      dummy_data.forEach((d, i) => {
        // d.y = i < engageData.length-1 ? 0 : 100
        d.y = [0, 0, 0, 0];
        d.z = [0, 0, 0, 0];
      });

      load ? setData(Data) : setData(dummy_data);

      // console.log(Data);
    });
  }, [load]);

  return (
    <Waypoint onEnter={() => setLoad(true)} onLeave={() => setLoad(false)}>
      <div className={style.container}>
        <h4 className={style.chart_title}>Time to hire</h4>
        <div className={style.row}>
          <div className={style.left__header_row}>
            <div className={style.title__sm}>Stage</div>
          </div>
          <div className={style.right__header_row}>
            <div className={style.title}>Weeks</div>
            <div className={style.legend_group}>
              <div className={style.legend_container}>
                <div className={style.legend_box_light}></div>
                <span className={style.legend_text}>Non-exec</span>
              </div>
              <div className={style.legend_container}>
                <div className={style.legend_box_dark}></div>
                <span className={style.legend_text}>Exec</span>
              </div>
            </div>
          </div>
        </div>

        {Data.map(row => (
          <div className={style.row} key={row.x + "time"}>
            <div className={style.left}>
              <span>{row.x}</span>
            </div>
            <div className={style.right}>
              <div className={style.bar_container_double}>
                <span className={style.toolbox}>
                  <Box
                    value={row.y}
                    maxDomain={30}
                    height={45}
                    // maxLabel={row.hover_avg}
                  />
                  <span
                    className={style.tooltip2}
                    style={{
                      left: `${parseInt(d3.mean(row.y)) * 30}px`,
                      width: `100px`,
                      top: `60px`
                    }}
                  >
                    {row.hover_avg}
                    <br />
                    {row.hover_range}
                  </span>
                </span>
                <span className={style.toolbox}>
                  <Box
                    value={row.z}
                    maxDomain={30}
                    height={45}
                    // maxLabel={row.hover_avg2}
                    color="#4E66A5"
                  />
                  <span
                    className={style.tooltip2}
                    style={{
                      left: `${parseInt(d3.mean(row.z)) * 30}px`,
                      width: `100px`,
                      top: `60px`
                    }}
                  >
                    {row.hover_avg2}
                    <br />
                    {row.hover_range2}
                  </span>
                </span>
              </div>
            </div>
          </div>
        ))}

        <div className={style.axis}>
          <div className={style.axis_item}>0 weeks</div>
          <div className={style.axis_item}>15 weeks</div>
          <div className={style.axis_item}>30 weeks</div>
        </div>
      </div>
    </Waypoint>
  );
};

export default HireTime;
