import React, { useState, useEffect } from "react";
import { sumBy } from "lodash";
import StackedBar from "components/stackedBar";
import style from "charts/shared.module.scss";
import * as d3 from "d3";
import data from "../thrive_data.csv";
import { Waypoint } from "react-waypoint";

const AttritionChart = () => {
  const [Data, setData] = useState([]);

  useEffect(() => {
    d3.csv(data).then(function(data) {
      var unsorted = [];
      var calc = [];
      var calcrange = [];

      var filtered = data.filter(function(d) {
        return d.Q40 !== "" && d.Q41 !== "";
      });

      filtered.forEach(function(value) {
        calc.push({
          stage: value.Q2,
          regret: parseFloat(value.Q41) * parseFloat(value.Q40),
          nonregret:
            parseFloat(value.Q40) -
            parseFloat(value.Q41) * parseFloat(value.Q40)
        });
      });

      filtered.forEach(function(value) {
        calcrange.push({
          stage: value.Q2,
          regret: parseFloat(value.Q41),
          nonregret: 1 - parseFloat(value.Q41)
        });
      });

      // console.log(calc)

      var mapped = d3.rollup(
        calc,
        v => d3.mean(v, d => d.nonregret),
        d => d.stage
      );
      // var low_non = d3.rollup(calcrange, v => d3.min(v, d => d.nonregret), d => d.stage)
      // var high_non = d3.rollup(calcrange, v => d3.max(v, d => d.nonregret), d => d.stage)
      // var low = d3.rollup(calcrange, v => d3.min(v, d => d.regret), d => d.stage)
      // var high = d3.rollup(calcrange, v => d3.max(v, d => d.regret), d => d.stage)

      d3.rollup(
        calc,
        v => d3.mean(v, d => d.regret),
        d => d.stage
      ).forEach(function(value, key) {
        var values = [];
        var order = { "Seed-A": 1, "B-C": 2, "D+": 3 };
        var label = {
          "Seed-A": "Seed - Series A",
          "B-C": "Series B-C",
          "D+": "Series D+"
        };
        values.push({ value: value, fill: "#E0E3E7", text: "#282828" });
        values.push({ value: mapped.get(key), fill: "#4E66A5" });
        unsorted.push({
          order: order[key],
          x: label[key],
          y: values,
          z: sumBy(values, "value")
        });
      });

      function compare(a, b) {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      }

      var Data = unsorted.sort(compare);

      setData(Data);

      console.log(Data);
    });
  }, []);

  const [load, setLoad] = useState(false);

  let comp;
  if (load) {
    comp = (
      <>
        {Data.map(row => (
          <div className={style.row} key={row.x + "attr"}>
            <div className={style.left}>
              <span>{row.x}</span>
            </div>
            <div className={style.right}>
              <span className={style.toolbox}>
                <StackedBar
                  data={row.y}
                  max={0.2}
                  // labels={row.y}
                  // labelText={row.z}
                />
                <span
                  className={style.tooltip}
                  style={{
                    left: `${parseInt(row.z * 100) * 40}px`,
                    width: `115px`,
                    top: `80px`
                  }}
                >
                  Avg Total Attrition {Math.round(row.z * 100)}%
                </span>
              </span>
            </div>
          </div>
        ))}
      </>
    );
  } else {
    comp = (
      <>
        {Data.map(row => (
          <div className={style.row} key={row.x + "attr"}>
            <div className={style.left}>
              <span>{row.x}</span>
            </div>
            <div className={style.right}>
              <span className={style.toolbox}>
                <StackedBar
                  data={[{ value: 0 }]}
                  max={0.2}
                  // labels={row.y}
                  // labelText={row.y}
                />
                <span
                  className={style.tooltip}
                  style={{
                    left: `${parseInt(row.z * 100) * 40}px`,
                    width: `115px`,
                    top: `80px`
                  }}
                >
                  Avg Total Attrition {Math.round(row.z * 100)}%
                </span>
              </span>
            </div>
          </div>
        ))}
      </>
    );
  }

  return (
    <Waypoint onEnter={() => setLoad(true)} onLeave={() => setLoad(false)}>
      <div className={style.container}>
        <div className={style.row}>
          <div className={style.left__header_row}>
            <div className={style.title__sm}>Stage</div>
          </div>
          <div className={style.right__header_row}>
            <div className={style.title}>Attrition</div>
            <div className={style.legend_group}>
              <div className={style.legend_container}>
                <div className={style.legend_box_light}></div>
                <span className={style.legend_text}>Regrettable</span>
              </div>
              <div className={style.legend_container}>
                <div className={style.legend_box_dark}></div>
                <span className={style.legend_text}>Non-regrettable</span>
              </div>
            </div>
          </div>
        </div>

        {comp}
        <div className={style.axis}>
          <div className={style.axis_item}>0%</div>
          <div className={style.axis_item}>10%</div>
          <div className={style.axis_item}>20%</div>
        </div>
      </div>
    </Waypoint>
  );
};

export default AttritionChart;
