import React, { useState, useEffect } from "react";
import style from "charts/shared.module.scss";
import Box from "components/box";
import * as d3 from "d3";
import data from "../thrive_data.csv";
import { Waypoint } from "react-waypoint";

const TargetComp = () => {
  const [Data, setData] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    d3.csv(data).then(function(data) {
      var unsorted = [];
      var temp = [];

      var filtered = data.filter(function(d) {
        return d.Q90 !== "" || d.Q91 !== "";
      });

      d3.group(filtered, d => d.Q2).forEach(function(value, key) {
        temp.push({ x: key, y: value });
      });

      temp.forEach(function(value) {
        var values = [];
        var values2 = [];
        var order = { "Seed-A": 1, "B-C": 2, "D+": 3 };
        var label = {
          "Seed-A": "Seed - Series A",
          "B-C": "Series B-C",
          "D+": "Series D+"
        };
        value.y.forEach(function(val) {
          if (val.Q90 !== "") {
            values.push(parseFloat(val.Q90));
          }
          if (val.Q91 !== "") {
            values2.push(parseFloat(val.Q91));
          }
        });
        unsorted.push({
          order: order[value.x],
          x: label[value.x],
          y: values,
          z: values2,
          hover_avg: "Avg " + Math.round((d3.mean(values) * 100) / 5) * 5 + "%",
          hover_range:
            "Range " +
            Math.round((d3.min(values) * 100) / 5) * 5 +
            "-" +
            Math.round((d3.max(values) * 100) / 5) * 5 +
            "%",
          hover_avg2:
            "Avg " + Math.round((d3.mean(values2) * 100) / 5) * 5 + "%",
          hover_range2:
            "Range " +
            Math.round((d3.min(values2) * 100) / 5) * 5 +
            "-" +
            Math.round((d3.max(values2) * 100) / 5) * 5 +
            "%"
        });
      });

      function compare(a, b) {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      }

      var Data = unsorted.sort(compare);

      // setData(Data);
      var dummy_data = JSON.parse(JSON.stringify(Data));

      dummy_data.forEach((d, i) => {
        d.y = [0, 0, 0, 0];
        d.z = [0, 0, 0, 0];
      });

      load ? setData(Data) : setData(dummy_data);

      // console.log(Data);
    });
  }, [load]);

  return (
    <Waypoint onEnter={() => setLoad(true)} onLeave={() => setLoad(false)}>
      <div className={style.container}>
        <h4 className={style.content_title}>Compensation Philosophy</h4>
        {/* <p className="hr-ops-team-paragraph">
          Data below was reported for all roles and does not account for
          potential technical, non-technical splits. Given the heightened
          competition in the talent market over the past 6-12 mos, we have,
          anecdotally, seen instances where companies target one percentile for
          business roles but flex up for technical and hard-to-get EPD roles.
        </p> */}
        <div className={style.row}>
          <div className={style.left__header_row}>
            <div className={style.title__sm}>Stage</div>
          </div>
          <div className={style.right__header_row}>
            <div className={style.title}>Target Compensation Percentile</div>
            <div className={style.legend_group}>
              <div className={style.legend_container}>
                <div className={style.legend_box_light}></div>
                <span className={style.legend_text}>Cash</span>
              </div>
              <div className={style.legend_container}>
                <div className={style.legend_box_dark}></div>
                <span className={style.legend_text}>Equity</span>
              </div>
            </div>
          </div>
        </div>

        {Data.map(row => (
          <div className={style.row} key={row.x + "target"}>
            <div className={style.left}>
              <span>{row.x}</span>
            </div>
            <div className={style.right}>
              <div className={style.bar_container_double}>
                <span className={style.toolbox}>
                  <Box
                    value={row.y}
                    maxDomain={1}
                    height={50}
                    // maxLabel={row.hover_avg}
                    light
                  />
                  <span
                    className={style.tooltip2}
                    style={{
                      left: `${parseInt(d3.mean(row.y) * 100) * 9}px`,
                      width: `80px`,
                      top: `70px`
                    }}
                  >
                    {row.hover_avg}
                    <br />
                    {row.hover_range}
                  </span>
                </span>
                <span className={style.toolbox}>
                  <Box
                    value={row.z}
                    maxDomain={1}
                    height={40}
                    color="#4E66A5"
                    // maxLabel={row.hover_avg2}
                  />
                  <span
                    className={style.tooltip2}
                    style={{
                      left: `${parseInt(d3.mean(row.z) * 100) * 9}px`,
                      width: `80px`,
                      top: `70px`
                    }}
                  >
                    {row.hover_avg2}
                    <br />
                    {row.hover_range2}
                  </span>
                </span>
              </div>
            </div>
          </div>
        ))}

        <div className={style.axis}>
          <div className={style.axis_item}>0%</div>
          <div className={style.axis_item}>50%</div>
          <div className={style.axis_item}>100%</div>
        </div>
        <h4 className={style.chart_title}>Equity Refresh</h4>
        <p className="hr-ops-team-paragraph">
          Companies rarely institute a formal equity refresh policy until after
          the Series B. Up to that point, any instance of a refresh is usually a
          “spot” refresh reserved in special instances for top performers.
          <br />
          <br />
          Once instated, the most common reported practice is for employees to
          become eligible for a refresh grant after 2 years of service to the
          company and then every 1-2 years, thereafter. Refresh grants are
          significantly lower than new hire grants, typically 25% whatever the
          new hire grant would be for the role at that time.
          <br />
          <br />
          Policies vary on cliff and vesting period; some companies have removed
          the cliff on refreshes entirely. Likewise, we’re seeing different
          schools of thought on whether refresh is a standard practice for all
          employees or tethered to performance.
          <br />
          <br />
          It’s also common practice to refresh equity upon an employee’s
          promotion to ensure the individual reaches equity parity with what
          that role would receive in market.
        </p>
      </div>
    </Waypoint>
  );
};

export default TargetComp;
