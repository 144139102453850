import React, { useState, useEffect } from "react";
import style from "charts/shared.module.scss";
import * as d3 from "d3";
import data from "../thrive_data.csv";

const RecruitRatio = () => {
  const [rec, setData] = useState([]);

  useEffect(() => {
    d3.csv(data).then(function(data) {
      var unsorted = [];

      var rec_tech_clean = data.filter(function(d) {
        return d.Q7 !== "";
      });
      var rec_nontech_clean = data.filter(function(d) {
        return d.Q8 !== "";
      });
      // var rec_exec_clean = data.filter(function(d) { return d.Q9 !== ''; })

      var rec_nontech = d3.rollup(
        rec_nontech_clean,
        v => d3.mean(v, d => d.Q8),
        d => d.Q2
      );
      // var rec_exec = d3.rollup(rec_exec_clean, v => d3.mean(v, d => d.Q9), d => d.Q2)
      // var rec_exec_low = d3.rollup(rec_exec_clean, v => d3.min(v, d => parseInt(d.Q9)), d => d.Q2)
      // var rec_exec_high = d3.rollup(rec_exec_clean, v => d3.max(v, d => parseInt(d.Q9)), d => d.Q2)
      var rec_nontech_low = d3.rollup(
        rec_nontech_clean,
        v => d3.min(v, d => parseInt(d.Q8)),
        d => d.Q2
      );
      var rec_nontech_high = d3.rollup(
        rec_nontech_clean,
        v => d3.max(v, d => parseInt(d.Q8)),
        d => d.Q2
      );
      var rec_tech_low = d3.rollup(
        rec_tech_clean,
        v => d3.min(v, d => parseInt(d.Q7)),
        d => d.Q2
      );
      var rec_tech_high = d3.rollup(
        rec_tech_clean,
        v => d3.max(v, d => parseInt(d.Q7)),
        d => d.Q2
      );

      d3.rollup(
        rec_tech_clean,
        v => d3.mean(v, d => d.Q7),
        d => d.Q2
      ).forEach(function(value, key) {
        var order = { "Seed-A": 1, "B-C": 2, "D+": 3 };
        var label = {
          "Seed-A": "Seed - Series A",
          "B-C": "Series B-C",
          "D+": "Series D+"
        };
        unsorted.push({
          order: order[key],
          stage: label[key],
          tech: parseInt(value),
          hover_tech:
            "Range " +
            rec_tech_low.get(key) +
            "-" +
            rec_tech_high.get(key) +
            " hires",
          non_tech: parseInt(rec_nontech.get(key)),
          hover_nontech:
            "Range " +
            rec_nontech_low.get(key) +
            "-" +
            rec_nontech_high.get(key) +
            " hires"
        }); //,exec:parseInt(rec_exec.get(key)),hover_exec:'Range: '+rec_exec_low.get(key)+' - '+rec_exec_high.get(key)+' hires'})
      });

      function compare(a, b) {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      }

      var rec = unsorted.sort(compare);

      setData(rec);
    });
  }, []);

  return (
    <div className={style.container}>
      <h4 className={style.chart_title}>
        Hires made per quarter per recruiter
      </h4>
      <div className={style.table}>
        <div className={style.header_row}>
          <div className={style.column}> Stage </div>
          <div className={style.column2}> Technical</div>
          <div className={style.column3}> Non-technical </div>
          {/* <div className={style.column4}> Executive </div> */}
        </div>

        {rec.map(row => (
          <div className={style.table_row} key={row.stage + "hires"}>
            <div className={style.column}> {row.stage} </div>
            <div className={style.column2}>
              <span className={style.toolbox}>
                {row.tech}{" "}
                <span className={style.tooltip3} style={{ width: `90px` }}>
                  {row.hover_tech}
                </span>
              </span>
            </div>
            <div className={style.column3}>
              <span className={style.toolbox}>
                {row.non_tech}{" "}
                <span className={style.tooltip3} style={{ width: `90px` }}>
                  {row.hover_nontech}
                </span>
              </span>
            </div>
            {/* <div className={style.column4}>
            <span className={style.toolbox}>{row.exec} <span className={style.tooltip3}>{row.hover_exec}</span>
            </span>
          </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecruitRatio;
