import React from "react";
import Select from "components/select";
import _, { find, reduce } from "lodash";
import cn from "classnames";
import { Waypoint } from "react-waypoint";

import {
  SEED_SERIES_A_DATA,
  SERIES_B_C_DATA,
  SERIES_D_DATA,
  SEED_SERIES_A_TECH,
  SERIES_B_C_TECH,
  SERIES_D_TECH,
  genderDemographicMap,
  DEMOGRAPHICS
} from "./data";
import Bars from "./bars";
import Legends from "./legends";
import Axis from "./axis";
import Strategy from "./strategy";
import style from "./index.module.scss";

const SEED_SERIES_A = "seed_series_a";
const SERIES_B_C = "series_b_c";
const SERIES_D = "series_d";

const DATA_MAP = {
  [SEED_SERIES_A]: SEED_SERIES_A_DATA,
  [SERIES_B_C]: SERIES_B_C_DATA,
  [SERIES_D]: SERIES_D_DATA
};

const TECH_MAP = {
  [SEED_SERIES_A]: SEED_SERIES_A_TECH,
  [SERIES_B_C]: SERIES_B_C_TECH,
  [SERIES_D]: SERIES_D_TECH
};

// DEI Strategy / Anti-Harrassment Policy
const STRATEGY_DATA = {
  [SEED_SERIES_A]: { strategy: 78, policy: 78, study: 0 },
  [SERIES_B_C]: { strategy: 89, policy: 67, study: 50 },
  [SERIES_D]: { strategy: 100, policy: 100, study: 88 }
};

const filterOptions = [
  { value: SEED_SERIES_A, label: "Seed - Series A" },
  { value: SERIES_B_C, label: "Series B - C" },
  { value: SERIES_D, label: "Series D+" }
];

const getOption = value =>
  find(filterOptions, option => option.value === value);
const getDataSet = value => DATA_MAP[value];
const getDefaultDemographicFilter = () =>
  reduce(
    DEMOGRAPHICS,
    (result, demographic) => ({ ...result, [demographic]: true }),
    {}
  );

const demographicFilterInitialState = {
  Female: getDefaultDemographicFilter(),
  Male: getDefaultDemographicFilter()
};

// FIXME: tooltip label
class DiversityInclusionChart extends React.Component {
  state = {
    selectedChart: SEED_SERIES_A,
    demographicFilter: demographicFilterInitialState,
    load: false
  };

  handleFilterChange = option => {
    const selectedChart = option.value;

    this.setState({
      selectedChart,
      demographicFilter: demographicFilterInitialState
    });
  };

  handleDemographicFilter = ({ gender, demographic }) => {
    const { demographicFilter } = this.state;

    const nextState = {
      ...demographicFilter,
      [gender]: {
        ...demographicFilter[gender],
        [demographic]: !demographicFilter[gender][demographic]
      }
    };

    this.setState({ demographicFilter: nextState });
    // console.log(this.state)
  };

  handleToggleSelectAll = ({ target: { checked } }) => {
    // checked = select all
    // initial state = all selected
    const emptyFilter = { Female: {}, Male: {} };
    const nextFilterState = checked
      ? demographicFilterInitialState
      : emptyFilter;

    this.setState({ demographicFilter: nextFilterState });
  };

  handleWaypoint = option => this.setState({ load: !this.state.load });

  render() {
    const { selectedChart, demographicFilter } = this.state;
    const dataSet = getDataSet(selectedChart);
    const strategyData = STRATEGY_DATA[selectedChart];
    const techData = TECH_MAP[selectedChart];
    console.log(this.state.load);

    const data = _.chain(dataSet)
      .orderBy(["gender", "count", "color"], ["desc", "desc", "desc"])
      .groupBy("seniority")
      .value();

    const datatech = _.chain(techData)
      .orderBy(["gender", "count", "color"], ["desc", "desc", "desc"])
      .groupBy("seniority")
      .value();

    const orderKeys = [
      "Individual contributors",
      "Managers",
      "Executive team",
      "Board"
    ];
    const orderKeystech = ["Technical roles", "Non-technical roles"];
    console.log(data);

    let comp, comp1;

    if (this.state.load) {
      comp = (
        <>
          <Bars
            data={data}
            orderKeys={orderKeys}
            demographicFilter={demographicFilter}
          />
        </>
      );
      comp1 = (
        <Bars
          data={datatech}
          orderKeys={orderKeystech}
          demographicFilter={demographicFilter}
          series={selectedChart}
          type={"technical"}
        />
      );
    } else {
      comp = <div></div>;
      comp1 = <div></div>;
    }

    return (
      <Waypoint onEnter={this.handleWaypoint} onLeave={this.handleWaypoint}>
        <div>
          <div className={style.top}>
            <div className={cn(style.top__row, style.select_container)}>
              <Select
                options={filterOptions}
                defaultValue={getOption(selectedChart)}
                onChange={this.handleFilterChange}
              />
            </div>
            <div className={style.top__row}>
              <Strategy {...strategyData} />
            </div>
          </div>
          <div className={style.titles}>
            <h4 className={style.chart_title_top}>
              Diversity, management Level
            </h4>
            <h4 className={style.legend_title_top}>Legend</h4>
          </div>
          <div className={style.bottom}>
            <div className={style.chart_container}>
              <div className={style.bars_axis_group}>
                {comp}
                <Axis
                  data={data}
                  orderKeys={orderKeys}
                  colorMap={genderDemographicMap}
                />
              </div>

              <Legends
                colorMap={genderDemographicMap}
                filter={demographicFilter}
                onClick={this.handleDemographicFilter}
                toggleSelectAll={this.handleToggleSelectAll}
              />
            </div>
          </div>
          <div className={style.titles}>
            <h4 className={style.chart_title_t}>
              Diversity, technical vs. non-technical
            </h4>
            <h4 className={style.legend_title}>Legend</h4>
          </div>
          <div className={style.bottom}>
            <div className={style.chart_container_small}>
              <div className={style.bars_axis_group}>
                {comp1}
                <Axis
                  data={datatech}
                  orderKeys={orderKeystech}
                  colorMap={genderDemographicMap}
                />
              </div>
              <Legends
                colorMap={genderDemographicMap}
                filter={demographicFilter}
                onClick={this.handleDemographicFilter}
                toggleSelectAll={this.handleToggleSelectAll}
              />
            </div>
          </div>
        </div>
      </Waypoint>
    );
  }
}

export default DiversityInclusionChart;
