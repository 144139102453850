// import { mapValues } from "lodash";
import React from "react";
// import PropTypes from 'prop-types';
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  // VictoryLabel,
  VictoryTooltip
} from "victory";

const DEFAULT_COLOR = "#E0E3E7";

// const CustomLabel = ({ value,hover }) =>
// <g>
// <VictoryLabel {...value}/>
// <VictoryTooltip
//   {...hover}
//   x={200} y={250}
//   orientation="top"
//   pointerLength={0}
//   cornerRadius={50}
//   flyoutWidth={100}
//   flyoutHeight={100}
//   flyoutStyle={{ fill: "black" }}
// />
// </g>

// const tagName = ""
// <tagName />

class CustomLabel extends React.Component {
  render() {
    return (
      <g>
        {/* <VictoryLabel {...this.props}/> */}
        <VictoryTooltip
          {...this.props}
          //x={200} y={250}
          orientation="right"
          pointerLength={0}
          cornerRadius={5}
          style={{
            fontSize: 5,
            fontWeight: "normal",
            fill: "white",
            textAlign: "left",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif'
          }}
          // width={20}
          // flyoutWidth={20}
          flyoutHeight={20}
          flyoutStyle={{ fill: "black", stroke: 0 }}
        />
      </g>
    );
  }
}

CustomLabel.defaultEvents = VictoryTooltip.defaultEvents;

const Bar = ({ value, groupsize, maxDomain }) => (
  <VictoryChart
    height={35 * groupsize}
    padding={{ top: 0, bottom: 0, right: 0, left: 100 }}
    domainPadding={21}
    maxDomain={{ y: maxDomain || 50 }}
  >
    <VictoryBar
      horizontal
      barWidth={15}
      style={{
        data: { fill: DEFAULT_COLOR },
        labels: {
          fontSize: 8,
          fontWeight: "normal",
          fill: "white",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif'
        }
      }}
      // labels={() => ''}
      labels={({ datum }) => "Avg " + datum.y + "% \n" + datum.hover}
      // labelComponent={<CustomLabel value={({ datum }) => datum.y} hover={({ datum }) => datum.hover}/>}
      labelComponent={<CustomLabel />}
      data={value}
      animate={{
        duration: 2000,
        onLoad: { duration: 2000 }
      }}
    />
    {/* <VictoryLabel
          text={labelText+'%'}
          x={({ datum }) => (datum.y * 4.6 * 100) / (maxDomain || 100)}
          y={50}
          style={{
            ontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSize: 8
          }}
        /> */}
    <VictoryAxis
      style={{
        axis: { stroke: "#4F4F4F" },
        tickLabels: {
          fontSize: 6,
          fontWeight: "normal",
          fill: "white",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif'
        }
      }}
    />
  </VictoryChart>
);

export default Bar;
