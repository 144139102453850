import React from "react";
import { map } from "lodash";
import style from "./index.module.scss";

// const UnrepresentedGroups = ({ data, colorMap }) => {
//   return (
//     <div className={style.unrepresented_group_container}>
//       <div className={style.unrepresented_group_header}>0%</div>
//       <div className={style.unrepresented_group_content}>
//         {map(data, (demographics, gender) => (
//           <div className={style.unrepresented_group_column}>
//             <div className={style.legend_gender_group__name}>{gender}</div>
//             {map(demographics, demographic => (
//               <div className={style.legend_item}>
//                 <span
//                   className={style.legend_item__icon}
//                   style={{
//                     backgroundColor: colorMap[gender][demographic]
//                   }}
//                 ></span>
//                 <span>{demographic}</span>
//               </div>
//             ))}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

const Axis = ({ data, orderKeys, colorMap }) => {
  // const [hover, setHover] = useState("");

  return (
    <div className={style.axis}>
      {map(orderKeys, seniority => {
        // const bySeniority = data[seniority];
        // const unrepresentedData = reduce(
        //   colorMap,
        //   (result, rows, gender) => ({
        //     ...result,
        //     [gender]: filter(
        //       Object.keys(rows),
        //       demographic =>
        //         !find(
        //           bySeniority,
        //           eachSeniority =>
        //             eachSeniority.gender === gender &&
        //             eachSeniority.demographic === demographic
        //         )
        //     )
        //   }),
        //   {}
        // );

        return (
          <div
            key={seniority + "ax"}
            className={style.axis_item}
            // onMouseEnter={() => setHover(seniority)}
            // onMouseLeave={() => setHover("")}
          >
            <div className={style.axis_item_label}>{seniority}</div>
            {/* {hover === seniority && (
              <UnrepresentedGroups
                data={unrepresentedData}
                colorMap={colorMap}
              />
            )} */}
          </div>
        );
      })}
    </div>
  );
};

export default Axis;
