import React, { useState, useEffect } from "react";
import style from "charts/shared.module.scss";
import Box from "components/box";
import * as d3 from "d3";
import data from "../thrive_data.csv";
import { Waypoint } from "react-waypoint";

const AcceptanceRate = () => {
  const [Data, setData] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    d3.csv(data).then(function(data) {
      var unsorted = [];
      var temp = [];

      var filtered = data.filter(function(d) {
        return d.Q13 !== "";
      });

      d3.group(filtered, d => d.Q2).forEach(function(value, key) {
        temp.push({ x: key, y: value });
      });

      temp.forEach(function(value) {
        var values = [];
        var order = { "Seed-A": 1, "B-C": 2, "D+": 3 };
        var label = {
          "Seed-A": "Seed - Series A",
          "B-C": "Series B-C",
          "D+": "Series D+"
        };
        value.y.forEach(function(val) {
          values.push(parseFloat(val.Q13));
        });
        unsorted.push({
          order: order[value.x],
          x: label[value.x],
          y: values,
          mean: "Avg: " + Math.round(d3.mean(values) * 100) + "%",
          hover:
            "Range: " +
            Math.round(d3.min(values) * 100) +
            "% - " +
            Math.round(d3.max(values) * 100) +
            "%"
        });
      });

      function compare(a, b) {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      }

      var Data = unsorted.sort(compare);

      // setData(Data);
      var dummy_data = JSON.parse(JSON.stringify(Data));

      dummy_data.forEach((d, i) => {
        // d.y = i < engageData.length-1 ? 0 : 100
        d.y = [0, 0, 0, 0];
      });

      load ? setData(Data) : setData(dummy_data);

      // console.log(Data);
    });
  }, [load]);

  return (
    <Waypoint onEnter={() => setLoad(true)} onLeave={() => setLoad(false)}>
      <div className={style.container}>
        <h4 className={style.chart_title}>Offer acceptance rate</h4>
        <div className={style.row}>
          <div className={style.left__header_row}>
            <div className={style.title__sm}>Stage</div>
          </div>
          <div className={style.right__header_row}>
            <div className={style.title}>% acceptance rate</div>
          </div>
        </div>

        {Data.map(row => (
          <div className={style.row} key={row.x + "accept"}>
            <div className={style.left}>
              <span>{row.x}</span>
            </div>
            <div className={style.right}>
              <span className={style.toolbox}>
                <Box value={row.y} maxDomain={1} />
                <span
                  className={style.tooltip}
                  style={{
                    left: `${parseInt(d3.mean(row.y) * 100) * 7}px`,
                    width: `105px`
                  }}
                >
                  {row.mean}
                  <br />
                  {row.hover}
                </span>
              </span>
            </div>
          </div>
        ))}
        <div className={style.axis}>
          <div className={style.axis_item}>0%</div>
          <div className={style.axis_item}>50%</div>
          <div className={style.axis_item}>100%</div>
        </div>
      </div>
    </Waypoint>
  );
};

export default AcceptanceRate;
