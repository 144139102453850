export const ADDITIONAL = {
  "Seed-A": {
    name: "Seed-A",
    retirementProviders: ["Slavic401k", "Guideline"],
    additionalProviders: [
      "Meal stipends",
      "Short and longterm disability",
      "Learning and development stipend",
      "Life insurance",
      "Childcare",
      "Commuter benefits",
      "Pet insurance",
      "Work-from-home stipend"
    ],
    percent401k: 0.75,
    percentMatch: 0,
    parentalLeave: { value: 12.333333333333334, hover: "Range 6-16 weeks" },
    pto_unlimited: 1,
    pto: { hover_range: "Range NaN-NaN days" }
  },
  "B-C": {
    name: "B-C",
    retirementProviders: ["Slavic401k", "Guideline", "Human Interest"],
    additionalProviders: [
      "Meal stipends",
      "Short and longterm disability",
      "Learning and development stipend",
      "Life insurance",
      "Childcare",
      "Commuter benefits",
      "Pet insurance",
      "Work-from-home stipend"
    ],
    percent401k: 0.8888888888888888,
    percentMatch: 0.25,
    parentalLeave: { value: 13.875, hover: "Range 9-24 weeks" },
    pto_unlimited: 0,
    pto: { pto: 31, hover_range: "Range 25-38 days" }
  },
  "D+": {
    name: "D+",
    retirementProviders: [
      "ADP",
      "Betterment",
      "Empower",
      "Fidelity",
      "ForUsAll",
      "Human Interest"
    ],
    additionalProviders: [
      "Meal stipends",
      "Short and longterm disability",
      "Learning and development stipend",
      "Life insurance",
      "Childcare",
      "Commuter benefits",
      "Pet insurance",
      "Work-from-home stipend"
    ],
    percent401k: 1,
    percentMatch: 0.125,
    parentalLeave: { value: 15.25, hover: "Range 12-18 weeks" },
    pto_unlimited: 0.3333333333333333,
    pto: { pto: 25, hover_range: "Range 20-30 days" }
  }
};
