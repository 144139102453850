import React from "react";
import { map, chain, values } from "lodash";
import style from "./index.module.scss";
import check from "./checkbox.svg";

const Legends = ({ colorMap, filter, onClick, toggleSelectAll }) => {
  const uniqueFilterValue = chain(filter)
    .values()
    .map(eachGender => values(eachGender))
    .flatten()
    .uniq()
    .value();
  const allSelected = uniqueFilterValue.length === 1 && uniqueFilterValue[0];

  return (
    <div className={style.legend_container}>
      <p className={style.legend_description}>
        Add or remove a group from the graph by selecting or unselecting the
        boxes below
      </p>
      <div className={style.checkbox_container}>
        <label>
          <input
            type="checkbox"
            checked={allSelected}
            onChange={toggleSelectAll}
          />
          <span className={style.checkbox_label_text}> Select All</span>
        </label>
      </div>
      <div className={style.option_container}>
        {map(colorMap, (items, gender) => (
          <div className={style.legend_gender_group} key={gender + "leg1"}>
            <div className={style.legend_gender_group__name}>{gender}</div>

            {map(items, (color, demographic) => (
              <div
                className={style.legend_item}
                key={demographic + "leg2"}
                // onClick={() => onClick({ gender, demographic })}
              >
                <div
                  className={style.legend_item__icon}
                  onClick={() => onClick({ gender, demographic })}
                  style={{
                    backgroundColor: filter[gender][demographic]
                      ? color
                      : "#ffffff00"
                    // opacity: filter[gender][demographic] ? 1 : 0
                  }}
                >
                  <img
                    src={check}
                    alt="check"
                    height={20}
                    width={16}
                    style={{
                      opacity: filter[gender][demographic] ? 1 : 0
                    }}
                  />
                  {/* <input
                  type="checkbox"
                  className={style.check}
                  onChange={() => onClick({ gender, demographic })}
                  // checked
                /> */}
                </div>
              </div>
            ))}
          </div>
        ))}
        <div className={style.legend_label_group}>
          {/* <div className={style.legend_gender_group__name}>{' '}</div> */}
          {map(colorMap.Female, (color, demographic) => (
            <div className={style.legend_item_label} key={color + "leg"}>
              <span className={style.legend_item__text}>{demographic}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Legends;
