import React from "react";
import { VictoryStack, VictoryBar, VictoryTooltip } from "victory";
import { map, sumBy, filter } from "lodash";
import style from "./index.module.scss";

const Bars = ({ data, orderKeys, demographicFilter, series, type }) => {
  return (
    <div className={style.bars}>
      {map(orderKeys, seniority => {
        const groupTotal = sumBy(data[seniority], "count");
        const rows = filter(
          data[seniority],
          row => demographicFilter[row.gender][row.demographic]
        );

        if (series === "series_d" && type === "technical") {
          return (
            <div className={style.bar_no_data}>
              Not enough data submitted by these companies.
            </div>
          );
        }

        return (
          <div className={style.bar_container} key={seniority}>
            <div className={style.stack_label}>
              {Math.round((sumBy(rows, "count") / groupTotal) * 100) + "%"}
            </div>
            <VictoryStack
              padding={0}
              maxDomain={{ y: groupTotal }}
              width={80}
              height={300}
            >
              {map(rows, (row, index) => {
                const { gender, demographic } = row;
                const percentage = Math.ceil((row.count / groupTotal) * 100);

                return (
                  <VictoryBar
                    key={index}
                    data={[row]}
                    x="seniority"
                    y="count"
                    barWidth={60}
                    labels={() =>
                      `${seniority}\n ${percentage}% ${gender}-${demographic}`
                    }
                    style={{
                      data: {
                        fill: row.color
                      }
                    }}
                    animate={{
                      duration: 2000,
                      onLoad: { duration: 2000 }
                    }}
                    labelComponent={
                      <VictoryTooltip
                        style={{
                          fontFamily: "'Helvetica Neue', Helvetica, sans-serif",
                          fontSize: 6,
                          fontWeight: "normal",
                          fill: "white",
                          textAlign: "left"
                        }}
                        dy={percentage < 10 ? 7 : 20}
                        dx={10}
                        pointerLength={0}
                        cornerRadius={5}
                        orientation="right"
                        flyoutStyle={{
                          fill:
                            "linear-gradient(180deg, rgba(16, 16, 16, 0.76) 0%, rgba(33, 33, 33, 0.6612) 100%)",
                          color: "white"
                        }}
                      />
                    }
                  />
                );
              })}
            </VictoryStack>
          </div>
        );
      })}
    </div>
  );
};

export default Bars;
