import React from "react";
import style from "charts/shared.module.scss";

const FutureOfWork = () => {
  return (
    <div className={style.container}>
      <div className={style.text_row}>
        <div className={style.text_left}>
          <h4 className={style.text_title}>Collaboration + Community</h4>
          <p className={style.text_body}>
            Companies across stages resoundingly reported back a shift in
            working style to empower employees and managers to determine more
            flexible working times and structures that best fit with their lives
            and business needs. Companies also commonly reported introducing
            company-wide ‘no-meeting’ blocks and a dedication to building
            process and tooling to enable better asynchronous communication and
            collaboration.
          </p>
          <br />
          <h4 className={style.text_title}>Performance Management + 1:1s</h4>
          <p className={style.text_body}>
            Companies reported an uptick in incorporating technology and tools
            to give more structure to their 1:1 process; even earlier stage
            companies, which traditionally deploy more ad hoc methods, shifted
            to formal, consistent processes supported by off-the-shelf
            technology solutions. Most noticeably teams brought on Lattice for
            1:1 management and employee engagement. We’re huge supporters of{" "}
            <b>Lattice</b> and can easily make an introduction for any teams
            interested.
          </p>
        </div>
        <div className={style.text_right}>
          <h4 className={style.text_title}>Recruiting</h4>
          <p className={style.text_body}>
            Over <b>95%</b> of companies reported being open to hiring employees
            from new geographies. There was clear consensus from companies that
            remote hiring is necessary in order to acquire the best talent.
          </p>
          <br />
          <h4 className={style.text_title}>Localized Compensation</h4>
          <p className={style.text_body}>
            In instances where companies have dispersed workforces, the data
            reported a roughly half and half split between companies that
            localized compensation according to geography and companies that
            kept compensation constant across all employees. In almost all
            instances companies reassesed perks and benefits in order to ensure
            they remain competitive.
          </p>
          <br />
          <h4 className={style.text_title}>Employer Branding</h4>
          <p className={style.text_body}>
            Participating companies commonly reported efforts to shift their
            brand and brand assets to reflect environments of inclusion and
            support for remote work.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FutureOfWork;
