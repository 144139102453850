import React from "react";
import Vaccination from "./vaccination";
import ReturnToWork from "./returnToWork";
import DaysInOffice from "./daysInOffice";
import RECharts from "./realEstate";
import style from "./index.module.scss";
import sharedStyle from "charts/shared.module.scss";
import FutureOfWork from "./futureOfWork";

const WorkplaceCharts = () => (
  <div className={style.container}>
    <div className={style.chart_container}>
      <ReturnToWork />
    </div>
    <div className={style.chart_container}>
      <DaysInOffice />
    </div>
    <div className={style.chart_container}>
      <Vaccination />
    </div>
  </div>
);

const REChart = () => (
  <div className={style.container}>
    <h4 className={style.chart_title} style={{ marginBottom: `0px` }}>
      Real estate footprint
    </h4>
    <div className={style.chart_container}>
      <div className={sharedStyle.legend_group} style={{ marginLeft: `0px` }}>
        <div className={sharedStyle.legend_container}>
          <div className={sharedStyle.legend_box_re1}></div>
          <span className={sharedStyle.legend_text}>No Change</span>
        </div>
        <div className={sharedStyle.legend_container}>
          <div className={sharedStyle.legend_box_re2}></div>
          <span className={sharedStyle.legend_text}>Increase</span>
        </div>
        <div className={sharedStyle.legend_container}>
          <div className={sharedStyle.legend_box_re3}></div>
          <span className={sharedStyle.legend_text}>Decrease</span>
        </div>
      </div>
      <RECharts />
    </div>
  </div>
);

const FutureText = () => (
  <div className={style.container}>
    <div className={style.chart_container}>
      <FutureOfWork />
    </div>
  </div>
);

export { WorkplaceCharts, REChart, FutureText };
