import React from "react";
import Select from "components/select";
import { find, map } from "lodash";
import { HRSPEND } from "./data";
import style from "./index.module.scss";
import sharedStyle from "charts/shared.module.scss";
import Box from "components/box";
import * as d3 from "d3";
import { Waypoint } from "react-waypoint";

const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0
});
const toDollars = number => numberFormatter.format(number);

let empty_data = JSON.parse(JSON.stringify(HRSPEND["Seed-A"]));

empty_data.name = "empty_data";

empty_data.breakdown.forEach(d => {
  d.y = [0, 0, 0];
});

let DATA_MAP = {
  [HRSPEND["Seed-A"].name]: HRSPEND["Seed-A"],
  [HRSPEND["B-C"].name]: HRSPEND["B-C"],
  [HRSPEND["D+"].name]: HRSPEND["D+"],
  empty_data: empty_data
};

const filterOptions = map(DATA_MAP, (_, key) => ({ label: key, value: key }));

const getDefaultOption = () => find(filterOptions, { value: "Seed-A" });

class HRSpend extends React.Component {
  state = {
    selectedChart: empty_data
    // load: false
  };

  handleChange = option => {
    if (option.value) {
      this.setState({ selectedChart: DATA_MAP[option.value] });
    } else {
      this.setState({ selectedChart: DATA_MAP["Seed-A"] });
    }
  };

  handleLeave = option => {
    this.setState({ selectedChart: DATA_MAP["empty_data"] });
  };

  // handleWaypoint = option =>
  // this.setState({ load: !this.state.load });

  render() {
    const {
      selectedChart: { name, eoyComp, equityRefresh, relocationComp }
    } = this.state;

    let breakd;

    breakd = (
      <div className={sharedStyle.container}>
        <div className={style.row_spend}>
          <div className={sharedStyle.left__header_row}>
            <div className={sharedStyle.title__sm}>Category</div>
          </div>
          <div className={style.right__header_row}>
            <div className={style.title}>Budget</div>
          </div>
          <div className={style.third__header_row}>
            <div className={style.title}>
              <span
                className={sharedStyle.tool}
                style={{
                  width: `85%`,
                  marginLeft: `15%`,
                  borderBottom: `0px`,
                  textDecoration: `underline dotted white`
                }}
              >
                Avg % of total HR Spend
                <span
                  className={sharedStyle.tooltip}
                  style={{ left: `100px`, width: `135px`, top: `40px` }}
                >
                  Calculated as a percent of total HR Spend reported in chart
                  above
                </span>
              </span>
            </div>
          </div>
        </div>

        {this.state.selectedChart.breakdown.map(row => (
          <div className={style.row_spend} key={row.x + "break"}>
            <div className={sharedStyle.left}>
              <span>{row.x}</span>
            </div>
            <div className={sharedStyle.right}>
              <div className={style.bar_container_spend}>
                <span className={sharedStyle.toolbox}>
                  <Box
                    value={row.y}
                    maxDomain={row.max}
                    anchor={"left"}
                    // maxLabel={`Avg ${toDollars(
                    //   Math.round(row.mean / 1000) * 1000
                    // )}`}
                    // hover={`Range ${row.data.nonExec.min}-${row.data.nonExec.max} weeks`}
                    height={45}
                  />
                  <span
                    className={sharedStyle.tooltip2}
                    style={{
                      left: `${parseInt(d3.mean(row.y)) / (row.max / 1000)}px`,
                      width: `135px`,
                      top: `60px`
                    }}
                  >
                    Avg {toDollars(Math.round(row.mean / 1000) * 1000)}
                    <br />
                    {row.hover}
                    <br />
                    {row.hover2}
                    <br />
                    {row.hover3}
                  </span>
                </span>
              </div>
            </div>
            <div className={sharedStyle.third}>
              <span className={style.avg_pct}>
                {Math.round(row.pct * 100) + "%"}
              </span>
            </div>
          </div>
        ))}

        <div className={style.axis}>
          <div className={sharedStyle.axis_item}>$0</div>
          <div className={sharedStyle.axis_item}>
            {toDollars(
              parseFloat(this.state.selectedChart.breakdown[0].max) / 2
            )}
          </div>
          <div className={sharedStyle.axis_item}>
            {toDollars(parseFloat(this.state.selectedChart.breakdown[0].max))}
          </div>
        </div>
      </div>
    );

    let note;
    if (
      this.state.selectedChart.name === "Seed-A" ||
      this.state.selectedChart.name === "empty_data"
    ) {
      note = (
        <p className="hr-ops-team-paragraph">
          For early stage companies, spending is typically ad hoc across DEI
          consultants, exec coaching, and learning and development.
          <br />
          <br />
          Anecdotally, we are noticing an uptick in learning and development
          spending as the talent market tightens and candidates increasingly
          prioritize their personal growth.
          <br />
          <br />
          Founders typically start exploring coaching options at the Series A.
        </p>
      );
    } else {
      note = <span></span>;
    }

    return (
      <Waypoint onEnter={this.handleChange} onLeave={this.handleLeave}>
        <div className={style.container}>
          <h4 className={sharedStyle.chart_title}>
            Breakdown of Annual HR Spend
          </h4>
          <div className={style.filter}>
            {" "}
            <div className={style.select_container}>
              <Select
                options={filterOptions.filter(d => d.label !== "empty_data")}
                defaultValue={getDefaultOption(name)}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />

          {breakd}
          {note}

          <div className={style.chart_container}>
            <div className={style.row}>
              <div className={style.content}>
                <div className={style.stat_container}>
                  <div className={style.basic_stat}>
                    {parseInt(eoyComp * 100) + "%"}
                  </div>
                  <div className={style.basic_stat_text}>
                    Avg headcount budget reserved for{" "}
                    <b>EOY Compensation Adjustments</b>
                  </div>
                </div>
              </div>

              <div className={style.content}>
                <div className={style.stat_container}>
                  <div className={style.basic_stat}>
                    {parseInt(equityRefresh * 100) + "%"}
                  </div>
                  <div className={style.basic_stat_text}>
                    Of companies have an <b>Equity Refresh Program</b>
                  </div>
                </div>
              </div>
              <div className={style.content}>
                <div className={style.stat_container}>
                  <div className={style.basic_stat}>
                    {parseInt(relocationComp * 100) + "%"}
                  </div>
                  <div className={style.basic_stat_text}>
                    Of companies <b>Adjusted Compensation</b> for employees who
                    chose to <b>Relocate Geographically</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Waypoint>
    );
  }
}

export default HRSpend;
