import React from "react";
import CandidateExperience from "./candidateExperience";
import AcceptanceRate from "./acceptanceRate";
import HireTime from "./hireTime";
import HireCost from "./hireCost";
import RecruitRatio from "./recruitRatio";
import OnboardingSatisfaction from "./onboardingSatisfaction";
import style from "./index.module.scss";

const RecruitingPerformanceCharts = () => (
  <div className={style.container}>
    <div className={style.chart_container}>
      <RecruitRatio />
    </div>
    <div className={style.chart_container}>
      <HireTime />
    </div>
    <div className={style.chart_container}>
      <HireCost />
    </div>
    <div className={style.chart_container}>
      <AcceptanceRate />
    </div>
  </div>
);

const HRPerformanceChart = () => (
  <div className={style.container}>
    <p className={style.paragraph}>
    Seed-Series A typically measure onboarding qualitatively until after they make their first talent hire. Additional HR services are measured in regular company surveys and impact studies are completed on an initiative-by-initiative basis (e.g., cost or time savings from a tool migration, new benefit provider).
    </p>
    <div className={style.chart_container}>
      <OnboardingSatisfaction />
    </div>
    <div className={style.chart_container}>
      <CandidateExperience />
    </div>
  </div>
);

export { RecruitingPerformanceCharts, HRPerformanceChart };
