import React from "react";
import style from "./index.module.scss";
// import sharedStyle from "charts/shared.module.scss";
import file from "./HR Board Slide Templates by Thrive.pptx";

const TakeawaysChart = () => (
  <div className={style.container}>
    <div className={style.content}>
      <h4 className={style.chart_title}>Accelerate your Board meeting</h4>
      <p className={style.takeaway_paragraph}>
        Check out our templates for HR Board reporting. Best-in-class
        organizations share a mix of quantitative metrics and qualitative
        insights in order to tee up the areas where the Board can best advise or
        provide on-the-ground operations support.
        <br />
        <br />
        <span className={style.link}>
          Download Board templates{" "}
          <b>
            <a href={file} download>
              HERE
            </a>
          </b>
          .
        </span>
      </p>
      <h4 className={style.chart_title}>Engage our CHRO experts</h4>
      <p className={style.takeaway_paragraph}>
        We’ve curated a small group of CHROs for 1:1 founder advisory, small
        group roundtables, and resource/tool creation. Collectively they have
        People and Operations strategies and insights from a range of top
        organizations, from pre to post IPO, including Peloton, Square, Amazon,
        Google, Stitch Fix, McKinsey, Box, and more.
        <br />
        <br />
        <span className={style.link}>
          Sign up{" "}
          <b>
            <a
              href="https://airtable.com/embed/shrU2dnP2pKrtVpJV?backgroundColor=green"
              target="_blank"
              rel="noopener noreferrer"
            >
              HERE
            </a>
          </b>{" "}
          to indicate where you’d like to engage.
        </span>
      </p>
    </div>
  </div>
);

export default TakeawaysChart;
