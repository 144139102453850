import React from "react";
import "./index.scss";
import logo from "images/thrive_logo.svg";

class Nav extends React.Component {
  componentDidMount() {
    const navLinks = document.querySelectorAll("nav a");

    navLinks.forEach(link => {
      link.addEventListener("click", event => {
        const { hash } = event.target;
        // handle download link
        if (!hash) return;

        event.preventDefault();

        const target = document.querySelector(hash);

        target &&
          target.scrollIntoView({
            behavior: "smooth",
            block: "start"
          });
      });
    });

    const logo = document.querySelector(".logo");
    logo.addEventListener("click", () =>
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    );
  }

  render() {
    return (
      <nav>
        <div className="nav-links">
          <a href="#overview" className="nav-link">
            Overview
          </a>
          <a href="#culture" className="nav-link">
            Culture
          </a>
          <a href="#performance" className="nav-link">
            Performance
          </a>
          <a href="#operations" className="nav-link">
            Operations
          </a>
          <a href="#workplace-experience" className="nav-link">
            Workplace Experience
          </a>
          <a href="#budget-headcount" className="nav-link">
            Budget + Headcount
          </a>
          {/* {this.props.isAdmin && (
            <a href="/credits" className="nav-link">
              Credits
            </a>
          )} */}
        </div>
        <div className="logo">
          <img src={logo} className="thrive-logo" alt="logo" />
          Thrive Talent
        </div>
      </nav>
    );
  }
}

export default Nav;
