import React, { useState, useEffect } from "react";
import { VictoryPie, VictoryGroup, VictoryTooltip } from "victory";
import style from "./index.module.scss";
import * as d3 from "d3";
import data from "../thrive_data.csv";
// import hq from "./headquarters.png";
import { Waypoint } from "react-waypoint";

const PieChart = ({ name, data }) => (
  <div className={style.chart_container}>
    <div className="chart-name">{name}</div>
    <div className={style.pie_flex}>
      <div className={style.pie_container}>
        <VictoryGroup>
          <VictoryPie
            data={data}
            width={200}
            height={200}
            innerRadius={60}
            labelComponent={
              <VictoryTooltip
                pointerLength={0}
                cornerRadius={5}
                flyoutStyle={{
                  fill:
                    "linear-gradient(180deg, rgba(16, 16, 16, 0.76) 0%, rgba(33, 33, 33, 0.6612) 100%)",
                  color: "white"
                }}
              />
            }
            style={{
              labels: {
                fontSize: 12,
                fontWeight: "normal",
                fill: "white",
                fontFamily: "'Helvetica Neue', Helvetica, sans-serif"
              },
              data: { fill: ({ datum }) => datum.color }
            }}
            animate={{
              duration: 2000,
              onEnter: { duration: 2000 }
            }}
          />
        </VictoryGroup>
      </div>
      <div className={style.legends}>
        {data.map(row => (
          <div key={row.x + "pie"}>
            <span
              className={style.legend_box}
              style={{ backgroundColor: row.color }}
            ></span>
            <span className={style.legend_text}>{row.x}</span>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const OverviewCharts = () => {
  const [fundingStageData, setData] = useState([]);
  const [Data2, setData2] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    d3.csv(data).then(function(data) {
      //seed overview chart
      var unsorted = [];
      var colors_fund = ["#466383", "#A9C4C9", "#EDF1F1"];
      var order = { "Seed-A": 1, "B-C": 2, "D+": 3 };
      var i = 0;
      d3.rollup(
        data,
        v => v.length,
        d => d.Q2
      ).forEach(function(value, key) {
        unsorted.push({
          order: order[key],
          x: key,
          y: value,
          color: colors_fund[i],
          label:
            Math.round((parseInt(value) / parseInt(data.length)) * 100) + "%"
        });
        i++;
      });

      function compare(a, b) {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      }

      var fundingStageData = unsorted.sort(compare);

      // setData(fundingStageData);

      //industry overview chart
      var unsort = [];
      var colors_ind = [
        "#263F5A",
        "#466383",
        "#E2E9E9",
        "#A9C4C9",
        "#727272",
        "#EDF1F1"
      ];
      var j = 0;
      var data_clean = data.filter(function(d) {
        return d.Q3 !== "Edtech";
      });
      d3.rollup(
        data_clean,
        v => v.length,
        d => d.Q3
      ).forEach(function(value, key) {
        unsort.push({
          x: key,
          y: value,
          color: colors_ind[j],
          label:
            Math.round((parseInt(value) / parseInt(data.length)) * 100) + "%"
        });
        j++;
      });

      function compare2(a, b) {
        if (a.x.toLowerCase() < b.x.toLowerCase()) {
          return -1;
        }
        if (a.x.toLowerCase() > b.x.toLowerCase()) {
          return 1;
        }
        return 0;
      }

      var industryData = unsort.sort(compare2);
      var dummy_data = JSON.parse(JSON.stringify(industryData));

      dummy_data.forEach((d, i) => {
        d.y = i < industryData.length - 1 ? 0 : 100;
      });

      load ? setData2(industryData) : setData2(dummy_data);

      var dummy_data2 = JSON.parse(JSON.stringify(fundingStageData));

      dummy_data2.forEach((d, i) => {
        d.y = i < fundingStageData.length - 1 ? 0 : 100;
      });

      load ? setData(fundingStageData) : setData(dummy_data2);
      // console.log(load)
      // console.log(Data2)

      // setData(fundingStageData);
      // setData2(industryData);
    });
  }, [load]);

  // let comp
  // if (load) {
  //   comp = <div className={style.flex_container}>
  //   <div className={style.container}>
  //     <div className={style.charts}>
  //       <PieChart name="Funding stage" data={fundingStageData} />
  //       <PieChart name="Industry" data={Data2} />
  //       <div className={style.chart_container}>
  //         <div className="chart-name">{"Headquarters"}</div>
  //         <p>Geographically dispersed, partcipating companies have increasingly shifted towards hybrid and remote workforces. HQs are based in multiple regions across the United States, South America, and Europe. </p>
  //         {/* <img src={hq} className="h-q" alt="logo" /> */}
  //       </div>
  //     </div>
  //   </div>
  // </div>
  // } else {
  //   comp = <span></span>
  //   }

  return (
    <Waypoint onEnter={() => setLoad(true)} onLeave={() => setLoad(false)}>
      <div className={style.flex_container}>
        <div className={style.container}>
          <div className={style.charts}>
            <PieChart name="Funding stage" data={fundingStageData} />
            <PieChart name="Industry" data={Data2} />
            <div className={style.chart_container}>
              <div className="chart-name">{"Headquarters"}</div>
              <p>
                Geographically dispersed, participating companies have
                increasingly shifted towards hybrid and remote workforces. HQs
                are based in multiple regions across the United States, South
                America, and Europe.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* {comp} */}
    </Waypoint>
  );
};

export default OverviewCharts;
