import React from "react";
import {
  VictoryBoxPlot,
  VictoryLabel,
  VictoryTooltip,
  VictoryChart
} from "victory";

const DEFAULT_COLOR = "#E0E3E7";
// const DEFAULT_MEDIAN = "#B1B9C3";
const DEFAULT_MEDIAN = "#7F8C9C";
const MEDIAN_BLUE = "#304067";

// const MEDIAN_BLUE = "#405488"
const fontFamily = "'Helvetica Neue', Helvetica, sans-serif";
const defualtFontSize = "9px";

const Box = ({
  value,
  labelText,
  labelPosition,
  hoverText,
  color,
  maxDomain,
  maxLabel,
  style,
  boxWidth,
  fontSize,
  height,
  anchor
}) => (
  // <div style={style}>
  <VictoryChart
    height={height || 65}
    padding={0}
    maxDomain={{ y: maxDomain || 100 }}
    animate={{
      duration: 2000,
      onEnter: { duration: 2000 }
    }}
  >
    <VictoryBoxPlot
      height={height || 65}
      boxWidth={boxWidth || 15}
      whiskerWidth={7}
      horizontal
      domain={{ y: [0, 100] }}
      maxDomain={{ y: maxDomain || 100 }}
      data={[{ x: 1, y: value, label: hoverText }]}
      maxLabels={maxLabel}
      maxLabelComponent={
        <VictoryLabel
          dx={0}
          dy={-12}
          style={{
            fontFamily,
            fontSize: fontSize ? fontSize : defualtFontSize,
            fontWeight: "normal",
            fill: "white"
          }}
        />
      }
      labelComponent={
        <VictoryTooltip
          pointerLength={0}
          cornerRadius={5}
          flyoutStyle={{
            fill:
              "linear-gradient(180deg, rgba(16, 16, 16, 0.76) 0%, rgba(33, 33, 33, 0.6612) 100%);",
            color: "white"
          }}
        />
      }
      style={{
        // fontFamily,
        labels: {
          fontSize: 12,
          fontWeight: "normal",
          fill: "white",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif'
        },
        // data: { fill: color || DEFAULT_COLOR },
        min: { stroke: color || DEFAULT_COLOR },
        max: { stroke: color || DEFAULT_COLOR },
        q1: { fill: color || DEFAULT_COLOR },
        q3: { fill: color || DEFAULT_COLOR },
        median: {
          stroke: color ? MEDIAN_BLUE : DEFAULT_MEDIAN,
          strokeWidth: 2
        },
        maxLabels: { fill: "white" }
      }}
    />
  </VictoryChart>
  // </div>
);

export default Box;
