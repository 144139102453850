import React from "react";
import Select from "components/select";
import { find, map, orderBy } from "lodash";
import { RATIOS } from "./data";
import style from "./index.module.scss";

const CHART_MAP = {
  [RATIOS["eCommerce/DTC"].x]: RATIOS["eCommerce/DTC"],
  [RATIOS.Fintech.x]: RATIOS.Fintech,
  [RATIOS.Healthtech.x]: RATIOS.Healthtech,
  [RATIOS.Marketplace.x]: RATIOS.Marketplace,
  [RATIOS.SaaS.x]: RATIOS.SaaS
};

const filterOptions = map(CHART_MAP, (chart, _) => ({
  label: chart.x,
  value: chart.x
}));

const getDefaultOption = selectedChart =>
  find(filterOptions, { value: selectedChart.x });

class TeamRatiosChart extends React.Component {
  state = {
    selectedChart: RATIOS["eCommerce/DTC"]
  };

  handleChange = option =>
    this.setState({ selectedChart: CHART_MAP[option.value] });

  render() {
    const { selectedChart } = this.state;
    const data = orderBy(selectedChart.y, "teamSize");

    return (
      <div className={style.container}>
        <div className={style.select_container}>
          <Select
            options={filterOptions}
            defaultValue={getDefaultOption(selectedChart)}
            onChange={this.handleChange}
          />
        </div>
        <div className={style.table}>
          <div className={style.header_row}>
            <div className={style.column}> Recruiting team </div>
            <div className={style.column2}> Annual hiring demand </div>
            <div className={style.column3}> Ratio </div>
          </div>

          {data.map(row => (
            <div className={style.table_row} key={row.hires + "ratios"}>
              <div className={style.column}> {row.teamSize} </div>
              <div className={style.column2}> {row.hires} </div>
              <div className={style.column3}> {row.ratio} </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default TeamRatiosChart;
