import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./index.scss";
import "uikit";
import Main from "./Main";
// import Main20 from "./thrive-2020/src/Main20"
import Credits from "./Credits";

const App = () => (
  <Router>
    <Switch>
      <Route path="/credits">
        <Credits />
      </Route>
      <Route path="/2020">{/* <Main20 /> */}</Route>
      <Route path="/2021">
        <Main />
      </Route>
      <Route path="/">
        <Main />
      </Route>
    </Switch>
  </Router>
);

ReactDOM.render(<App />, document.getElementById("root"));
