import React from "react";
import Nav from "nav";
import OverviewCharts from "charts/overviewCharts";
import EmployeeEngagementChart from "charts/employeeEngagementChart";
import EmployeeTenureChart from "charts/employeeTenure";
import AttritionChart from "charts/attritionChart";
// import PTOChart from "charts/ptoChart";
import DiversityInclusionChart from "charts/diversityInclusionChart";
import HrOpsBarChart from "charts/hrOpsBarChart";
// import TechStack from "charts/techStack";
import TeamRatiosChart from "charts/teamRatiosChart";
import HeadcountChart from "charts/headcount";
import BenefitsProvidersChart from "charts/benefitsProvidersChart";
import AdditionalBenefitsChart from "charts/additionalBenefitsChart";
import {
  RecruitingPerformanceCharts,
  HRPerformanceChart
} from "charts/performanceCharts";
import { WorkplaceCharts, REChart, FutureText } from "charts/workplaceCharts";
import { BudgetCharts } from "charts/budgetCharts";

import thriveLogo from "images/large_logo.png";
import "./Main.scss";
import Authenticated from "./Authenticated";
import TakeawaysChart from "charts/takeaways";
import logo from "images/thrive_logo.svg";
// import { render } from "react-dom";

function Main({ isAdmin }) {
  return (
    <div className="app">
      <Nav isAdmin={isAdmin} />
      <section id="landing">
        <div className="landing-heading-container">
          <div className="landing-column-left">
            <h1 className="landing-heading">
              Company Culture + People team performance
            </h1>
            <h2 className="landing-sub-heading">
              Data Benchmark Report by Thrive Talent
            </h2>
            {/* <img src={thriveLogo} width="155" alt="logo"></img> */}
          </div>
          <div className="landing-column-right">
            <img
              src={thriveLogo}
              className="thrive_logo"
              alt="thrive-logo"
            ></img>
          </div>
        </div>

        {/* <div className="user-guide-container">
          <img src={downArrow} className="down-arrow" alt="down-arrow"></img>
        </div> */}
      </section>

      <section id="overview">
        <div className="overview-heading-container">
          <h1>Overview of Participating Portfolio Companies</h1>
          <p className="overview-paragraph">
            Data was sourced and anonymized in mid 2021 from 30+ portfolio
            companies.
          </p>
        </div>
        <OverviewCharts />
        {/* <Waypoint onEnter={()=>{ <OverviewCharts/>}} /> */}
      </section>
      <section id="culture">
        <h1 className="culture-heading">Company Culture + Health</h1>
        <div className="culture-content">
          <div className="culture-content-left">
            <h3 className="content-title">Employee Engagement</h3>
            <p className="culture-content-paragraph">
              Seed-Series A companies typically gauge employee engagement via
              informal surveys. As companies scale, they often implement a
              survey to more systematically measure and evaluate through time.
            </p>
          </div>
          <div className="culture-content-right">
            <EmployeeEngagementChart />
          </div>
        </div>
        <div className="culture-content">
          <div className="culture-content-left">
            <h3 className="content-title">Average Employee Tenure</h3>
            <p className="culture-content-paragraph">
              Seed-Series A data not included. The shorter lifespan of
              early-stage companies (for some surveyed, as short as 6 mos) makes
              for an inaccurate signal on this metric.
            </p>
          </div>
          <div className="culture-content-right">
            <EmployeeTenureChart />
          </div>
        </div>
        <div className="culture-content">
          <div className="culture-content-left">
            <h3 className="content-title">Attrition</h3>
          </div>
          <div className="culture-content-right">
            <AttritionChart />
          </div>
        </div>
        <div className="culture-content">
          <h3 className="content-title">Diversity</h3>
          <div className="diversity-inclusion-paragraph-container">
            <p className="diversity-inclusion-paragraph">
              While there is no one metric that will tell a company if it is
              diverse and inclusive, there are a mixture of datapoints that give
              a pulse on how things are going and how they could be better. Use
              the following benchmarks as a starting reference point for
              diversity. Qualitative feedback is an important overlay, and many
              mid-to-later stage companies roll out dedicated inclusion surveys
              (e.g., access to opportunities and resources, decision making) to
              further understand D&I efforts.
            </p>
          </div>
          <div className="culture-content-right">
            <DiversityInclusionChart />
          </div>
          <h3>DEI Tactics </h3>
          <div className="diversity-inclusion-paragraph-container">
            <p className="diversity-inclusion-notes">
              Participating companies across the board reported increased DEI
              budget and spending across recruitment efforts, resourcing, and
              employee education respectively. They likewise reported larger
              cross sections of the organization and leadership taking active
              roles in the DEI effort, establishing DEI as a firm-wide strategic
              initiative answerable to at the board level. Additional tactics
              shared include:
            </p>
            <ul>
              <li>
                <p className="diversity-inclusion-notes">
                  Forming DEI committees and taskforces
                </p>
              </li>
              <li>
                <p className="dei_hover_1">
                  <span className="hover_text">
                    Improved DEI data tracking and company-wide OKRs
                    <span className="tooltip">
                      We recommend exploring Twine Labs for data tracking and
                      visualization.
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p className="diversity-inclusion-notes">
                  Ramping sourcing and outreach pipelines to +90% URMs and URGs
                </p>
              </li>
              <li>
                <p className="diversity-inclusion-notes">
                  Increased resourcing for ERGs
                </p>
              </li>
              <li>
                <p className="dei_hover_1">
                  <span className="hover_text">
                    Employee training and racial equity education
                    <span className="tooltip">
                      Lots of companies have had success with Paradigm and
                      LIfeLabs. If interested, we can easily make an
                      introduction.
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p className="diversity-inclusion-notes">
                  Donation matching for racial equity efforts
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section id="performance">
        <h1>People Team Performance</h1>
        <h3 className="content-title">Recruiting Performance</h3>
        <div className="culture-content-right">
          <RecruitingPerformanceCharts />
        </div>
        <h3 className="content-title">HR & People Ops Performance</h3>
        <div className="culture-content-right">
          <HRPerformanceChart />
        </div>
      </section>

      <section id="operations">
        <h1 className="heading">People Team Operations</h1>

        <div className="operations-content ops-team-ratios">
          <h3 className="content-title">Recruiting Team Ratios</h3>
          <p className="ops-team-paragraph__left">
            A recruiting team ratio is typically 3 recruiters : 1 sourcer : 1
            coordinator.
          </p>
          <p className="ops-team-paragraph__left">
            Ratios vary by team and investment in agencies, tools, and
            recruiting partnerships. Below are actual ratios of recruiting teams
            to hiring team demand across the portfolio.
          </p>
          <div className="culture-content-right">
            <TeamRatiosChart />
          </div>
        </div>

        <div className="operations-content">
          <h3 className="content-title">HR & People Ops Team Ratios</h3>
          <div className="culture-content-right">
            <HrOpsBarChart />
          </div>
        </div>

        {/* <div className="operations-content">
          <h3 className="content-title">
            Tech Stack
          </h3>
          <TechStack />
        </div> */}

        <div className="operations-content">
          <h3 className="content-title">
            Benefits <span className="heading__plus">+</span> Providers
          </h3>
          <div className="hr-ops-team-paragraph-container">
            <p className="hr-ops-team-paragraph">
              An overview of benefits are provided below as a starter reference.
              Benefits vary, please reach out for further plan details and
              considerations.
            </p>
          </div>
          <h3 className="content-title">Healthcare Benefits</h3>
          <div className="culture-content-right">
            <BenefitsProvidersChart />
          </div>
          <h3 className="content-title">Additional Benefits</h3>
          <div className="culture-content-right">
            <AdditionalBenefitsChart />
          </div>
        </div>
      </section>
      <section id="workplace-experience">
        <h1>Workplace Experience</h1>
        <h3 className="content-title">Return to Office</h3>
        <div className="hr-ops-team-paragraph-container">
          <p className="hr-ops-team-paragraph">
            The majority of companies are approaching return to work with a
            hybrid model, broadly defined as a mix of in-person and remote work.
            Within the hybrid approach, we’re seeing a great deal of variability
            as companies define unique models that best fit their business and
            employees’ needs. More detail below.
            <br />
            <br />
            No companies reported fully in-person models.
          </p>
        </div>
        <div className="culture-content-right">
          <WorkplaceCharts />
        </div>
        <REChart />
        <h3 className="content-title">Future of Work</h3>
        <div className="hr-ops-team-paragraph-container">
          <p className="hr-ops-team-paragraph">
            Future of work is an active and ongoing conversation. Sign up for
            our roundtables below to deep dive into this conversation.
          </p>
        </div>
        <FutureText />
      </section>
      <section id="budget-headcount">
        <h1>Budget + Headcount</h1>
        <h3 className="content-title">Budget</h3>
        <div className="culture-content-right">
          <BudgetCharts />
        </div>
        <div className="headcount-content">
          <h3 className="content-title">Headcount</h3>
          <p className="ops-team-paragraph__left">
            See below for a breakdown of FTEs by function.
          </p>
          <HeadcountChart />
        </div>
        <h1>Onward</h1>
        <TakeawaysChart />
      </section>
      <footer style={{ marginTop: `100px` }}>
        <img src={logo} className="thrive-footer-logo" alt="logo" />
        <br />
        <span className="footer-logo-text">Thrive Talent</span>
        <br />
        <span className="footer-text">
          Made in partnership with Data Culture
        </span>
      </footer>
    </div>
  );
}

export default Authenticated(Main);
