import React from "react";
import { VictoryBar, VictoryStack, VictoryLabel, VictoryChart } from "victory";

const fontFamily = "'Helvetica Neue', Helvetica, sans-serif";

const defualtFontSize = 8;

const StackedBar = ({ data, labelText, hoverText, max }) => {
  return (
    <VictoryChart padding={0} height={65}>
      <VictoryStack maxDomain={{ y: max || 1 }}>
        {data.map(row => (
          <VictoryBar
            key={row.fill + "stack"}
            barWidth={15}
            horizontal
            data={[{ x: 1, y: row.value }]}
            style={{
              fontFamily,
              data: { fill: row.fill || "#263F5A" },
              labels: {
                fontFamily,
                fontSize: defualtFontSize,
                fill: row.text || "white"
              }
            }}
            animate={{
              duration: 2000,
              onLoad: { duration: 2000 }
            }}
            labels={`${Math.round(row.value * 100)}%`}
            // labels={(datum) => datum.y +  "%"}
            labelComponent={<VictoryLabel dx={-2} textAnchor="end" />}
          />
        ))}
      </VictoryStack>
    </VictoryChart>
  );
};

export default StackedBar;
