import React, { useState, useEffect } from "react";
import Box from "components/box";
import style from "charts/shared.module.scss";
import * as d3 from "d3";
import data from "../thrive_data.csv";
import { Waypoint } from "react-waypoint";

const EmployeeTenureChart = () => {
  const [tenureData, setData] = useState([]);
  const [load, setLoad] = useState(false);
  // const [tenureData_d, setData2] = useState([]);

  useEffect(() => {
    d3.csv(data).then(function(data) {
      var unsorted = [];
      var ten = [];

      var filtered = data.filter(function(d) {
        return d.Q39 !== "";
      });

      d3.group(filtered, d => d.Q2).forEach(function(value, key) {
        ten.push({ x: key, y: value });
      });

      ten.forEach(function(value) {
        var values = [];
        var order = { "Seed-A": 1, "B-C": 2, "D+": 3 };
        var label = {
          "Seed-A": "Seed - Series A",
          "B-C": "Series B-C",
          "D+": "Series D+"
        };
        value.y.forEach(function(val) {
          values.push(parseInt(val.Q39));
        });
        if (value.x === "B-C") {
          unsorted.push({
            order: order[value.x],
            x: label[value.x],
            y: values,
            mean: "Avg " + Math.round(d3.mean(values)) + " months",
            hover:
              "Range " +
              Math.round(d3.min(values)) +
              "-" +
              Math.round(d3.max(values)) +
              " months"
          });
        } else if (value.x === "D+") {
          unsorted.push({
            order: order[value.x],
            x: label[value.x],
            y: values,
            mean: "Avg " + Math.round(d3.mean(values)) + " months",
            hover:
              "Range " +
              Math.round(d3.min(values)) +
              "-" +
              Math.round(d3.max(values)) +
              " months"
          });
        }
      });

      function compare(a, b) {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      }

      var tenureData = unsorted.sort(compare);

      // setData(tenureData);
      var dummy_data = JSON.parse(JSON.stringify(tenureData));

      dummy_data.forEach((d, i) => {
        // d.y = i < engageData.length-1 ? 0 : 100
        d.y = [0, 0, 0, 0];
      });

      load ? setData(tenureData) : setData(dummy_data);

      // console.log(tenureData);
    });
  }, [load]);

  return (
    <Waypoint onEnter={() => setLoad(true)} onLeave={() => setLoad(false)}>
      <div className={style.container}>
        <div className={style.row}>
          <div className={style.left__header_row}>
            <div className={style.title__sm}>Stage</div>
          </div>
          <div className={style.right__header_row}>
            <div className={style.title}>Months</div>
          </div>
        </div>

        {tenureData.map(row => (
          <div className={style.row} key={row.x + "tenure"}>
            <div className={style.left}>
              <span>{row.x}</span>
            </div>
            <div className={style.right}>
              <span className={style.toolbox}>
                <Box
                  value={row.y}
                  // labelText={`Avg ${row.data.average}%`}
                  // hoverText={`Range ${row.data.min}-${row.data.max}%`}
                  // color="#A9C4C9"
                  maxDomain={50}
                  // maxLabel={row.mean}
                />
                <span
                  className={style.tooltip}
                  style={{
                    left: `${parseInt(d3.mean(row.y)) * 23}px`,
                    width: `110px`
                  }}
                >
                  {row.mean}
                  <br />
                  {row.hover}
                </span>
              </span>
            </div>
          </div>
        ))}
        <div className={style.axis}>
          <div className={style.axis_item}>0 months</div>
          <div className={style.axis_item}>25 months</div>
          <div className={style.axis_item}>50 months</div>
        </div>
      </div>
    </Waypoint>
  );
};

export default EmployeeTenureChart;
