import React, { useState, useEffect } from "react";
import Box2 from "components/box";
import style from "./index.module.scss";
import sharedStyle from "charts/shared.module.scss";
import * as d3 from "d3";
import data from "../thrive_data.csv";
import { Waypoint } from "react-waypoint";

// const fontFamily = "Helvetica Neue";
// const DEFAULT_COLOR = "#E0E3E7";
// const defualtFontSize = '12px';
// const labelStyle = { fontFamily, fontSize: 10, fontWeight: "bold" };

const HrOpsBarChart = () => {
  const [Data, setData] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    d3.csv(data).then(function(data) {
      var unsorted = [];
      var temp = [];

      var filtered = data.filter(function(d) {
        return d.Q118 !== "";
      });

      d3.group(filtered, d => d.Q1).forEach(function(value, key) {
        temp.push({ x: key, y: value });
      });

      temp.forEach(function(value) {
        var values = [];
        var order = { "300-500": 1, "150-300": 2, "50-150": 3, "0-50": 4 };
        value.y.forEach(function(val) {
          values.push(parseInt(val.Q118));
        });
        unsorted.push({
          order: order[value.x],
          x: value.x,
          y: values,
          mean: "Avg " + Math.round(d3.mean(values)),
          hover:
            "Range " +
            Math.round(d3.min(values)) +
            "-" +
            Math.round(d3.max(values))
        });
      });

      function compare(a, b) {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      }

      var Data = unsorted.sort(compare);

      // setData(Data);
      var dummy_data = JSON.parse(JSON.stringify(Data));

      dummy_data.forEach((d, i) => {
        d.y = [0, 0, 0, 0];
      });

      load ? setData(Data) : setData(dummy_data);

      // console.log(Data);
    });
  }, [load]);

  return (
    <Waypoint onEnter={() => setLoad(true)} onLeave={() => setLoad(false)}>
      <div className={style.container} style={{ paddingTop: `20px` }}>
        <div className={style.flex}>
          <div className={style.y_axis}>Total employees</div>
          <div className={style.chart_container}>
            {Data.map(row => (
              <div className={style.row} key={row.x + "hrops"}>
                <div className={style.left}>{row.x}</div>
                <div className={sharedStyle.right}>
                  <span className={sharedStyle.toolbox}>
                    <Box2
                      value={row.y}
                      height={50}
                      maxDomain={20}
                      // maxLabel={row.mean}
                      anchor={"left"}
                    />
                    <span
                      className={sharedStyle.tooltip}
                      style={{
                        left: `${parseInt(d3.mean(row.y)) * 50}px`,
                        width: `60px`
                      }}
                    >
                      {row.mean}
                      <br />
                      {row.hover}
                    </span>
                  </span>
                </div>
              </div>
            ))}
            <div className={style.axis}>
              <div className={sharedStyle.axis_item}>0</div>
              <div className={sharedStyle.axis_item}>5</div>
              <div className={sharedStyle.axis_item}>10</div>
              <div className={sharedStyle.axis_item}>15</div>
              <div className={sharedStyle.axis_item}>20</div>
            </div>
          </div>
        </div>
        <div className={style.x_axis}>HR/People Ops Team</div>
      </div>
    </Waypoint>
  );
};

export default HrOpsBarChart;
