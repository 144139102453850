import React from "react";
import { compact } from "lodash";
import thriveLogo2 from "images/thrive-logo2.png";
import "./Authenticated.scss";


const fetchAuthenticated = () => localStorage.getItem("authenticated");
const saveAuthenticated = () => localStorage.setItem("authenticated", true);
const fetchIsAdmin = () => localStorage.getItem("isAdmin");
const saveIsAdmin = () => localStorage.setItem("isAdmin", true);

const VALID_PASSWORDS = compact([
  process.env.REACT_APP_PASSWORD,
  process.env.REACT_APP_ADMIN_PASSWORD
]);
const ADMIN_PASSWORD = process.env.REACT_APP_ADMIN_PASSWORD;

const Authenticated = Component =>
  class extends React.Component {
    state = {
      authenticated: fetchAuthenticated(),
      authenticating: false,
      password: "",
      isAdmin: fetchIsAdmin(),
      error: false
    };

    logIn = () => {
      this.setState(
        () => ({ authenticating: true, error: false }),
        () => {
          setTimeout(() => {
            const isPasswordMatching = VALID_PASSWORDS.includes(
              this.state.password
            );
            const isAdmin = this.state.password === ADMIN_PASSWORD;

            isPasswordMatching && saveAuthenticated();
            isAdmin && saveIsAdmin();

            this.setState({
              authenticating: false,
              authenticated: isPasswordMatching,
              error: !isPasswordMatching,
              isAdmin
            });
          }, 500);
        }
      );
    };

    handlePasswordUpdate = ({ target: { value } }) =>
      this.setState({ password: value, error: false });

    render() {
      const { authenticated, authenticating, password, error } = this.state;

      if (!authenticated) {
        return (
          <div className="login-page">
            <img src={thriveLogo2} width="155" alt="logo2"></img>
            <div className="form">
              <input
                className={`uk-input field password ${
                  error ? "uk-form-danger" : ""
                }`}
                type="password"
                placeholder="Password"
                onChange={this.handlePasswordUpdate}
                // Log in on enter
                onKeyDown={({ keyCode }) => keyCode === 13 && this.logIn()}
                disabled={authenticating}
              />
              <button
                className="uk-button uk-button-default field"
                type="button"
                onClick={this.logIn}
                disabled={authenticating || !password}
              >
                Log in
              </button>
            </div>
            {error && <div>Incorrect password</div>}
          </div>
        );
      }

      return <Component isAdmin={this.state.isAdmin} {...this.props} />;
    }
  };

export default Authenticated;
