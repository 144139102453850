import React, { useState, useEffect } from "react";
import Box from "components/box";
// import smallPie from "components/smallPie";
import { VictoryPie, VictoryChart, VictoryLabel } from "victory";
import style from "charts/shared.module.scss";
import * as d3 from "d3";
import data from "../thrive_data.csv";
import { Waypoint } from "react-waypoint";

const CandidateExperience = () => {
  const [Data, setData] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    d3.csv(data).then(function(data) {
      var unsorted = [];
      var temp = [];
      var pie_bc = [];
      var pie_d = [];

      var b = data.filter(function(d) {
        return d.Q2 === "B-C";
      }).length;
      var d = data.filter(function(d) {
        return d.Q2 === "D+";
      }).length;

      var filtered = data.filter(function(d) {
        return d.Q11 !== "" && d.Q2 !== "Seed-A";
      });

      var answer_b = filtered.filter(function(d) {
        return d.Q2 === "B-C";
      }).length;
      var answer_d = filtered.filter(function(d) {
        return d.Q2 === "D+";
      }).length;

      pie_bc.push({ x: "Answer", y: answer_b });
      pie_bc.push({ x: "No Answer", y: b - answer_b });
      pie_d.push({ x: "Answer", y: answer_d });
      pie_d.push({ x: "No Answer", y: d - answer_d });

      // setData3(pie_bc)
      // setData4(pie_d)

      d3.group(filtered, d => d.Q2).forEach(function(value, key) {
        temp.push({ x: key, y: value });
      });

      temp.forEach(function(value) {
        var values = [];
        var order = { "Seed-A": 1, "B-C": 2, "D+": 3 };
        var label = {
          "Seed-A": "Seed - Series A",
          "B-C": "Series B-C",
          "D+": "Series D+"
        };
        value.y.forEach(function(val) {
          values.push(parseFloat(val.Q11));
        });
        if (value.x === "B-C") {
          unsorted.push({
            order: order[value.x],
            x: label[value.x],
            y: values,
            mean: "Avg " + Math.round(d3.mean(values) * 100) + "%",
            hover:
              "Range " +
              Math.round(d3.min(values) * 100) +
              "-" +
              Math.round(d3.max(values) * 100) +
              "%",
            donut: pie_bc
          });
        } else {
          unsorted.push({
            order: order[value.x],
            x: label[value.x],
            y: values,
            mean: "Avg " + Math.round(d3.mean(values) * 100) + "%",
            hover:
              "Range " +
              Math.round(d3.min(values) * 100) +
              "-" +
              Math.round(d3.max(values) * 100) +
              "%",
            donut: pie_d
          });
        }
      });

      function compare(a, b) {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      }

      var Data = unsorted.sort(compare);

      // setData(Data);
      var dummy_data = JSON.parse(JSON.stringify(Data));

      dummy_data.forEach((d, i) => {
        d.y = [0, 0, 0, 0];
      });

      load ? setData(Data) : setData(dummy_data);

      console.log("cand:" + load);
    });
  }, [load]);

  return (
    <Waypoint onEnter={() => setLoad(true)} onLeave={() => setLoad(false)}>
      <div className={style.container}>
        <h4 className={style.chart_title}>Candidate experience score</h4>
        <div className={style.row}>
          <div className={style.left__header_row}>
            <div className={style.title__sm}>
              % of companies measuring candidate experience
            </div>
          </div>
          <div className={style.right__header_row}>
            <div className={style.title_donut}>Score</div>
          </div>
        </div>

        {Data.map(row => (
          <div className={style.row} key={row.x + "cand"}>
            <div className={style.left_donut}>
              <div className={style.donut_label}>{row.x}</div>
              <div className={style.donut_box}>
                <VictoryChart padding={0}>
                  <VictoryPie
                    data={row.donut}
                    padding={0}
                    radius={95}
                    labels={() => null}
                    innerRadius={55}
                    colorScale={["#6F89CC", "#F8FBFF"]}
                    animate={{
                      duration: 2000,
                      onLoad: { duration: 2000 }
                    }}
                  />
                  <VictoryLabel
                    text={
                      Math.round(
                        (row.donut[0].y / (row.donut[0].y + row.donut[1].y)) *
                          100
                      ) + "%"
                    }
                    x={225}
                    y={155}
                    textAnchor="middle"
                    style={{
                      fontSize: 40,
                      fill: "white",
                      fontFamily: "'Helvetica Neue', Helvetica, sans-serif"
                    }}
                  />
                </VictoryChart>
              </div>
            </div>
            <div className={style.right}>
              <span className={style.toolbox}>
                <Box value={row.y} maxDomain={1} />
                <span
                  className={style.tooltip}
                  style={{
                    left: `${parseInt(d3.mean(row.y) * 100) * 7}px`,
                    width: `80px`
                  }}
                >
                  {row.mean}
                  <br />
                  {row.hover}
                </span>
              </span>
            </div>
          </div>
        ))}

        <div className={style.axis_donut}>
          <div className={style.axis_item}>0%</div>
          <div className={style.axis_item}>50%</div>
          <div className={style.axis_item}>100%</div>
        </div>
      </div>
    </Waypoint>
  );
};

export default CandidateExperience;
