import { includes, find } from "lodash";

const WHITE = "White";
const ASIAN = "Asian";
const HISPANIC = "Hispanic";
const BLACK = "Black";
const NATIVE_HAWAIIAN = "Native Hawaiian or Pacific Islander";
const NATIVE_AMERICAN = "American Indian or Alaskan Native";
const TWO_OR_MORE = "Two or More Races";
export const DEMOGRAPHICS = [
  WHITE,
  ASIAN,
  HISPANIC,
  BLACK,
  NATIVE_HAWAIIAN,
  NATIVE_AMERICAN,
  TWO_OR_MORE
];

const pipe = (...fns) => x => fns.reduce((v, f) => f(v), x);

const normalizeDemographic = rawDemographic =>
  find(DEMOGRAPHICS, demographic => includes(rawDemographic, demographic));

const normalizeData = dataSet =>
  dataSet.map(row => ({
    ...row,
    gender: includes(row.demographic, "Male") ? "Male" : "Female",
    rawDemographic: row.demographic,
    demographic: normalizeDemographic(row.demographic)
  }));

export const genderDemographicMap = {
  // Female: {
  //   [WHITE]: "#C5DBEB",
  //   [ASIAN]: "#4DAFDA",
  //   [HISPANIC]: "#6992CE",
  //   [BLACK]: "#2C7670",
  //   [NATIVE_HAWAIIAN]: "#608D67",
  //   [NATIVE_AMERICAN]: "#757575",
  //   [TWO_OR_MORE]: "#3C5186"
  // },
  Female: {
    [WHITE]: "#263F5A",
    [ASIAN]: "#2A4F7B",
    [HISPANIC]: "#4170A7",
    [BLACK]: "#5288C7",
    [NATIVE_HAWAIIAN]: "#7EAEE6",
    [NATIVE_AMERICAN]: "#A8C9EF",
    [TWO_OR_MORE]: "#CCE1F8"
  },
  // Male: {
  //   [WHITE]: "#758088",
  //   [ASIAN]: "#396A7F",
  //   [HISPANIC]: "#475C79",
  //   [BLACK]: "#284D4A",
  //   [NATIVE_HAWAIIAN]: "#425946",
  //   [NATIVE_AMERICAN]: "#4D4D4D",
  //   [TWO_OR_MORE]: "#313B55"
  // }
  Male: {
    [WHITE]: "#171717",
    [ASIAN]: "#383737",
    [HISPANIC]: "#4A4A4A",
    [BLACK]: "#747373",
    [NATIVE_HAWAIIAN]: "#969696",
    [NATIVE_AMERICAN]: "#AFAEAE",
    [TWO_OR_MORE]: "#D1D1D1"
  }
};

const injectColor = dataSet =>
  dataSet.map(row => {
    const { gender, demographic } = row;

    const color = genderDemographicMap[gender][demographic];

    if (!color) {
      console.log("unhandled demographic for gender", demographic, gender);
    }

    return {
      ...row,
      color
    };
  });

export const SEED_SERIES_A_DATA = pipe(
  normalizeData,
  injectColor
)([
  { seniority: "Board", demographic: "Male-Hispanic/Latino", count: 5 },
  { seniority: "Board", demographic: "Male-White", count: 8 },
  { seniority: "Board", demographic: "Male-Two or More Races", count: 1 },
  {
    seniority: "Executive team",
    demographic: "Male-Hispanic/Latino",
    count: 7
  },
  { seniority: "Executive team", demographic: "Male-White", count: 19 },
  {
    seniority: "Executive team",
    demographic: "Male-Asian or Asian American",
    count: 3
  },
  {
    seniority: "Executive team",
    demographic: "Male-Two or More Races",
    count: 2
  },
  {
    seniority: "Executive team",
    demographic: "Female-Hispanic/Latino",
    count: 1
  },
  { seniority: "Executive team", demographic: "Female-White", count: 6 },
  {
    seniority: "Executive team",
    demographic: "Female-Asian or Asian American",
    count: 1
  },
  { seniority: "Managers", demographic: "Male-Hispanic/Latino", count: 2 },
  { seniority: "Managers", demographic: "Male-White", count: 5 },
  {
    seniority: "Managers",
    demographic: "Male-Black or African American",
    count: 1
  },
  {
    seniority: "Managers",
    demographic: "Male-Asian or Asian American",
    count: 1
  },
  { seniority: "Managers", demographic: "Female-Hispanic/Latino", count: 1 },
  { seniority: "Managers", demographic: "Female-White", count: 8 },
  {
    seniority: "Managers",
    demographic: "Female-Black or African American",
    count: 2
  },
  {
    seniority: "Managers",
    demographic: "Female-Asian or Asian American",
    count: 1
  },
  {
    seniority: "Individual contributors",
    demographic: "Male-Hispanic/Latino",
    count: 3
  },
  {
    seniority: "Individual contributors",
    demographic: "Male-White",
    count: 15
  },
  {
    seniority: "Individual contributors",
    demographic: "Male-Asian or Asian American",
    count: 3
  },
  {
    seniority: "Individual contributors",
    demographic: "Male-Two or More Races",
    count: 3
  },
  {
    seniority: "Individual contributors",
    demographic: "Female-Hispanic/Latino",
    count: 1
  },
  {
    seniority: "Individual contributors",
    demographic: "Female-White",
    count: 4
  },
  {
    seniority: "Individual contributors",
    demographic: "Female-Black or African American",
    count: 3
  },
  {
    seniority: "Individual contributors",
    demographic: "Female-Asian or Asian American",
    count: 3
  },
  {
    seniority: "Individual contributors",
    demographic: "Female-Two or More Races",
    count: 4
  }
]);

export const SERIES_B_C_DATA = pipe(
  normalizeData,
  injectColor
)([
  { seniority: "Board", demographic: "Male-White", count: 12 },
  { seniority: "Board", demographic: "Male-Asian or Asian American", count: 6 },
  {
    seniority: "Board",
    demographic: "Female-Asian or Asian American",
    count: 1
  },
  {
    seniority: "Executive team",
    demographic: "Male-Hispanic/Latino",
    count: 3
  },
  { seniority: "Executive team", demographic: "Male-White", count: 25 },
  {
    seniority: "Executive team",
    demographic: "Male-Asian or Asian American",
    count: 4
  },
  {
    seniority: "Executive team",
    demographic: "Male-Two or More Races",
    count: 1
  },
  {
    seniority: "Executive team",
    demographic: "Female-Hispanic/Latino",
    count: 2
  },
  { seniority: "Executive team", demographic: "Female-White", count: 15 },
  {
    seniority: "Executive team",
    demographic: "Female-Asian or Asian American",
    count: 2
  },
  {
    seniority: "Executive team",
    demographic: "Female-Two or More Races",
    count: 2
  },
  { seniority: "Managers", demographic: "Male-Hispanic/Latino", count: 8 },
  { seniority: "Managers", demographic: "Male-White", count: 59 },
  {
    seniority: "Managers",
    demographic: "Male-Asian or Asian American",
    count: 5
  },
  { seniority: "Managers", demographic: "Male-Two or More Races", count: 5 },
  { seniority: "Managers", demographic: "Female-Hispanic/Latino", count: 8 },
  { seniority: "Managers", demographic: "Female-White", count: 60 },
  {
    seniority: "Managers",
    demographic: "Female-Black or African American",
    count: 6
  },
  {
    seniority: "Managers",
    demographic: "Female-Asian or Asian American",
    count: 15
  },
  { seniority: "Managers", demographic: "Female-Two or More Races", count: 3 },
  {
    seniority: "Individual contributors",
    demographic: "Male-Hispanic/Latino",
    count: 16
  },
  {
    seniority: "Individual contributors",
    demographic: "Male-White",
    count: 161
  },
  {
    seniority: "Individual contributors",
    demographic: "Male-Black or African American",
    count: 24
  },
  {
    seniority: "Individual contributors",
    demographic: "Male-Asian or Asian American",
    count: 27
  },
  {
    seniority: "Individual contributors",
    demographic: "Male-Two or More Races",
    count: 9
  },
  {
    seniority: "Individual contributors",
    demographic: "Female-Hispanic/Latino",
    count: 30
  },
  {
    seniority: "Individual contributors",
    demographic: "Female-White",
    count: 82
  },
  {
    seniority: "Individual contributors",
    demographic: "Female-Black or African American",
    count: 17
  },
  {
    seniority: "Individual contributors",
    demographic: "Female-Asian or Asian American",
    count: 37
  },
  {
    seniority: "Individual contributors",
    demographic: "Female-Two or More Races",
    count: 9
  }
]);

export const SERIES_D_DATA = pipe(
  normalizeData,
  injectColor
)([
  { seniority: "Board", demographic: "Male-Hispanic/Latino", count: 1 },
  { seniority: "Board", demographic: "Male-White", count: 3 },
  {
    seniority: "Board",
    demographic: "Male-Black or African American",
    count: 4
  },
  { seniority: "Board", demographic: "Male-Asian or Asian American", count: 3 },
  { seniority: "Board", demographic: "Male-Two or More Races", count: 1 },
  { seniority: "Board", demographic: "Female-Hispanic/Latino", count: 1 },
  { seniority: "Board", demographic: "Female-White", count: 2 },
  {
    seniority: "Board",
    demographic: "Female-Asian or Asian American",
    count: 2
  },
  {
    seniority: "Executive team",
    demographic: "Male-Hispanic/Latino",
    count: 3
  },
  { seniority: "Executive team", demographic: "Male-White", count: 35 },
  {
    seniority: "Executive team",
    demographic: "Male-Black or African American",
    count: 3
  },
  {
    seniority: "Executive team",
    demographic: "Male-Asian or Asian American",
    count: 13
  },
  {
    seniority: "Executive team",
    demographic: "Male-Two or More Races",
    count: 2
  },
  {
    seniority: "Executive team",
    demographic: "Female-Hispanic/Latino",
    count: 2
  },
  { seniority: "Executive team", demographic: "Female-White", count: 30 },
  {
    seniority: "Executive team",
    demographic: "Female-Black or African American",
    count: 4
  },
  {
    seniority: "Executive team",
    demographic: "Female-Asian or Asian American",
    count: 5
  },
  {
    seniority: "Executive team",
    demographic: "Female-Two or More Races",
    count: 3
  },
  { seniority: "Managers", demographic: "Male-Hispanic/Latino", count: 11 },
  { seniority: "Managers", demographic: "Male-White", count: 101 },
  {
    seniority: "Managers",
    demographic: "Male-Black or African American",
    count: 22
  },
  {
    seniority: "Managers",
    demographic: "Male-Asian or Asian American",
    count: 25
  },
  { seniority: "Managers", demographic: "Male-Two or More Races", count: 7 },
  { seniority: "Managers", demographic: "Female-Hispanic/Latino", count: 22 },
  { seniority: "Managers", demographic: "Female-White", count: 136 },
  {
    seniority: "Managers",
    demographic: "Female-Black or African American",
    count: 40
  },
  {
    seniority: "Managers",
    demographic: "Female-Asian or Asian American",
    count: 34
  },
  { seniority: "Managers", demographic: "Female-Two or More Races", count: 16 },
  {
    seniority: "Individual contributors",
    demographic: "Male-Hispanic/Latino",
    count: 41
  },
  {
    seniority: "Individual contributors",
    demographic: "Male-White",
    count: 301
  },
  {
    seniority: "Individual contributors",
    demographic: "Male-Black or African American",
    count: 38
  },
  {
    seniority: "Individual contributors",
    demographic: "Male-Native Hawaiian or Pacific Islander",
    count: 3
  },
  {
    seniority: "Individual contributors",
    demographic: "Male-Asian or Asian American",
    count: 94
  },
  {
    seniority: "Individual contributors",
    demographic: "Male-Two or More Races",
    count: 25
  },
  {
    seniority: "Individual contributors",
    demographic: "Female-Hispanic/Latino",
    count: 68
  },
  {
    seniority: "Individual contributors",
    demographic: "Female-White",
    count: 369
  },
  {
    seniority: "Individual contributors",
    demographic: "Female-Black or African American",
    count: 74
  },
  {
    seniority: "Individual contributors",
    demographic: "Female-Asian or Asian American",
    count: 118
  },
  {
    seniority: "Individual contributors",
    demographic: "Female-American Indian or Alaskan Native",
    count: 3
  },
  {
    seniority: "Individual contributors",
    demographic: "Female-Two or More Races",
    count: 41
  }
]);

export const SEED_SERIES_A_TECH = pipe(
  normalizeData,
  injectColor
)([
  {
    seniority: "Technical roles",
    demographic: "Male-Hispanic/Latino",
    count: 4
  },
  { seniority: "Technical roles", demographic: "Male-White", count: 24 },
  {
    seniority: "Technical roles",
    demographic: "Male-Black or African American",
    count: 1
  },
  {
    seniority: "Technical roles",
    demographic: "Male-Asian or Asian American",
    count: 4
  },
  {
    seniority: "Technical roles",
    demographic: "Male-Two or More Races",
    count: 4
  },
  { seniority: "Technical roles", demographic: "Female-White", count: 8 },
  {
    seniority: "Technical roles",
    demographic: "Female-Asian or Asian American",
    count: 3
  },
  {
    seniority: "Technical roles",
    demographic: "Female-Two or More Races",
    count: 1
  },
  {
    seniority: "Non-technical roles",
    demographic: "Male-Hispanic/Latino",
    count: 2
  },
  { seniority: "Non-technical roles", demographic: "Male-White", count: 17 },
  {
    seniority: "Non-technical roles",
    demographic: "Male-Black or African American",
    count: 5
  },
  {
    seniority: "Non-technical roles",
    demographic: "Male-Asian or Asian American",
    count: 5
  },
  {
    seniority: "Non-technical roles",
    demographic: "Male-Two or More Races",
    count: 4
  },
  {
    seniority: "Non-technical roles",
    demographic: "Female-Hispanic/Latino",
    count: 1
  },
  { seniority: "Non-technical roles", demographic: "Female-White", count: 39 },
  {
    seniority: "Non-technical roles",
    demographic: "Female-Black or African American",
    count: 16
  },
  {
    seniority: "Non-technical roles",
    demographic: "Female-Asian or Asian American",
    count: 5
  },
  {
    seniority: "Non-technical roles",
    demographic: "Female-Two or More Races",
    count: 9
  }
]);

export const SERIES_B_C_TECH = pipe(
  normalizeData,
  injectColor
)([
  {
    seniority: "Technical roles",
    demographic: "Male-Hispanic/Latino",
    count: 7
  },
  { seniority: "Technical roles", demographic: "Male-White", count: 98 },
  {
    seniority: "Technical roles",
    demographic: "Male-Black or African American",
    count: 11
  },
  {
    seniority: "Technical roles",
    demographic: "Male-Asian or Asian American",
    count: 22
  },
  {
    seniority: "Technical roles",
    demographic: "Male-Two or More Races",
    count: 3
  },
  {
    seniority: "Technical roles",
    demographic: "Female-Hispanic/Latino",
    count: 8
  },
  { seniority: "Technical roles", demographic: "Female-White", count: 28 },
  {
    seniority: "Technical roles",
    demographic: "Female-Black or African American",
    count: 7
  },
  {
    seniority: "Technical roles",
    demographic: "Female-Asian or Asian American",
    count: 21
  },
  {
    seniority: "Technical roles",
    demographic: "Female-Two or More Races",
    count: 2
  },
  {
    seniority: "Non-technical roles",
    demographic: "Male-Hispanic/Latino",
    count: 12
  },
  { seniority: "Non-technical roles", demographic: "Male-White", count: 111 },
  {
    seniority: "Non-technical roles",
    demographic: "Male-Black or African American",
    count: 10
  },
  {
    seniority: "Non-technical roles",
    demographic: "Male-Asian or Asian American",
    count: 11
  },
  {
    seniority: "Non-technical roles",
    demographic: "Male-Two or More Races",
    count: 6
  },
  {
    seniority: "Non-technical roles",
    demographic: "Female-Hispanic/Latino",
    count: 12
  },
  { seniority: "Non-technical roles", demographic: "Female-White", count: 108 },
  {
    seniority: "Non-technical roles",
    demographic: "Female-Black or African American",
    count: 21
  },
  {
    seniority: "Non-technical roles",
    demographic: "Female-Asian or Asian American",
    count: 33
  },
  {
    seniority: "Non-technical roles",
    demographic: "Female-Two or More Races",
    count: 11
  }
]);

export const SERIES_D_TECH = pipe(
  normalizeData,
  injectColor
)([
  {
    seniority: "Technical roles",
    demographic: "Male-Hispanic/Latino",
    count: 14
  },
  { seniority: "Technical roles", demographic: "Male-White", count: 109 },
  {
    seniority: "Technical roles",
    demographic: "Male-Black or African American",
    count: 28
  },
  {
    seniority: "Technical roles",
    demographic: "Male-Native Hawaiian or Pacific Islander",
    count: 1
  },
  {
    seniority: "Technical roles",
    demographic: "Male-Asian or Asian American",
    count: 57
  },
  {
    seniority: "Technical roles",
    demographic: "Male-Two or More Races",
    count: 7
  },
  {
    seniority: "Technical roles",
    demographic: "Female-Hispanic/Latino",
    count: 26
  },
  { seniority: "Technical roles", demographic: "Female-White", count: 109 },
  {
    seniority: "Technical roles",
    demographic: "Female-Black or African American",
    count: 96
  },
  {
    seniority: "Technical roles",
    demographic: "Female-Asian or Asian American",
    count: 52
  },
  {
    seniority: "Technical roles",
    demographic: "Female-American Indian or Alaskan Native",
    count: 1
  },
  {
    seniority: "Technical roles",
    demographic: "Female-Two or More Races",
    count: 14
  },
  {
    seniority: "Non-technical roles",
    demographic: "Male-Hispanic/Latino",
    count: 19
  },
  { seniority: "Non-technical roles", demographic: "Male-White", count: 72 },
  {
    seniority: "Non-technical roles",
    demographic: "Male-Black or African American",
    count: 25
  },
  {
    seniority: "Non-technical roles",
    demographic: "Male-Asian or Asian American",
    count: 22
  },
  {
    seniority: "Non-technical roles",
    demographic: "Male-Two or More Races",
    count: 11
  },
  {
    seniority: "Non-technical roles",
    demographic: "Female-Hispanic/Latino",
    count: 64
  },
  { seniority: "Non-technical roles", demographic: "Female-White", count: 222 },
  {
    seniority: "Non-technical roles",
    demographic: "Female-Black or African American",
    count: 151
  },
  {
    seniority: "Non-technical roles",
    demographic: "Female-Asian or Asian American",
    count: 58
  },
  {
    seniority: "Non-technical roles",
    demographic: "Female-American Indian or Alaskan Native",
    count: 5
  },
  {
    seniority: "Non-technical roles",
    demographic: "Female-Two or More Races",
    count: 28
  }
]);
