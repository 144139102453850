import React, { useState, useEffect } from "react";
import { VictoryPie, VictoryGroup, VictoryTooltip } from "victory";
import style from "./index.module.scss";
import * as d3 from "d3";
import data from "../thrive_data.csv";
import { Waypoint } from "react-waypoint";

const PieChart = ({ name, data }) => (
  <div className={style.chart_container_re}>
    <div className="chart-name">{name}</div>
    <div className={style.pie_flex}>
      <div className={style.pie_container}>
        <VictoryGroup>
          <VictoryPie
            data={data}
            width={200}
            height={200}
            innerRadius={60}
            animate={{
              duration: 2000,
              onLoad: { duration: 2000 }
            }}
            labelComponent={
              <VictoryTooltip
                pointerLength={0}
                cornerRadius={5}
                flyoutStyle={{
                  fill:
                    "linear-gradient(180deg, rgba(16, 16, 16, 0.76) 0%, rgba(33, 33, 33, 0.6612) 100%)",
                  color: "white"
                }}
              />
            }
            style={{
              labels: {
                fontSize: 12,
                fontWeight: "normal",
                fill: "white",
                fontFamily: "'Helvetica Neue', Helvetica, sans-serif"
              },
              data: { fill: ({ datum }) => datum.color }
            }}
          />
        </VictoryGroup>
      </div>
      {/* <div className={style.legends}>
      {data.map(row => (
        <div>
          <span
            className={style.legend_box}
            style={{ backgroundColor: row.color }}
          ></span>
          <span className={style.legend_text}>{row.x}</span>
        </div>
      ))}
    </div> */}
    </div>
  </div>
);

const RECharts = () => {
  const [AData, setData] = useState([]);
  const [BData, setData2] = useState([]);
  const [DData, setData3] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    d3.csv(data).then(function(data) {
      function compare(a, b) {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      }

      //A chart
      var unsorted = [];
      var legend = { "No Change": 0, Increase: 1, Decrease: 2 };
      var colors_fund = ["#E6E6E6", "#A9C5EB", "#6A87D1"];
      //  var i = 0;

      var re_series_a = data.filter(function(d) {
        return d.Q2 === "Seed-A" && d.Q46 !== "";
      });

      d3.rollup(
        re_series_a,
        v => v.length,
        d => d.Q46
      ).forEach(function(value, key) {
        unsorted.push({
          order: legend[key],
          x: key,
          y: value,
          color: colors_fund[legend[key]],
          label:
            Math.round((parseInt(value) / parseInt(re_series_a.length)) * 100) +
            "%"
        });
        // i++;
      });
      var AData = unsorted.sort(compare);
      // setData(AData);

      //B chart
      var unsortedb = [];
      //  var j = 0;

      var re_series_b = data.filter(function(d) {
        return d.Q2 === "B-C" && d.Q46 !== "";
      });

      d3.rollup(
        re_series_b,
        v => v.length,
        d => d.Q46
      ).forEach(function(value, key) {
        unsortedb.push({
          order: legend[key],
          x: key,
          y: value,
          color: colors_fund[legend[key]],
          label:
            Math.round((parseInt(value) / parseInt(re_series_a.length)) * 100) +
            "%"
        });
        // j++;
      });
      var BData = unsortedb.sort(compare);
      // setData2(BData);

      //D chart
      var unsortedd = [];
      //  var k = 0;

      var re_series_d = data.filter(function(d) {
        return d.Q2 === "D+" && d.Q46 !== "";
      });

      d3.rollup(
        re_series_d,
        v => v.length,
        d => d.Q46
      ).forEach(function(value, key) {
        unsortedd.push({
          order: legend[key],
          x: key,
          y: value,
          color: colors_fund[legend[key]],
          label:
            Math.round((parseInt(value) / parseInt(re_series_a.length)) * 100) +
            "%"
        });
        // k++;
      });
      var DData = unsortedd.sort(compare);
      // setData3(DData);
      var dummy_data = JSON.parse(JSON.stringify(AData));

      dummy_data.forEach((d, i) => {
        d.y = i < AData.length - 1 ? 0 : 100;
      });

      load ? setData(AData) : setData(dummy_data);

      var dummy_data2 = JSON.parse(JSON.stringify(BData));

      dummy_data2.forEach((d, i) => {
        d.y = i < BData.length - 1 ? 0 : 100;
      });

      load ? setData2(BData) : setData2(dummy_data2);

      var dummy_data3 = JSON.parse(JSON.stringify(DData));

      dummy_data3.forEach((d, i) => {
        d.y = i < DData.length - 1 ? 0 : 100;
      });

      load ? setData3(DData) : setData3(dummy_data3);
    });
  }, [load]);

  return (
    <Waypoint onEnter={() => setLoad(true)} onLeave={() => setLoad(false)}>
      <div className={style.flex_container}>
        <div className={style.container_re}>
          <div className={style.charts}>
            <PieChart name="Seed - Series A" data={AData} />
            <PieChart name="Series B - C" data={BData} />
            <PieChart name="Series D - F" data={DData} />
          </div>
        </div>
      </div>
    </Waypoint>
  );
};

export default RECharts;
