export const RATIOS = {
  Marketplace: {
    x: "Marketplace",
    y: [
      { hires: 167, teamSize: 4, ratio: "1:40" },
      { hires: 124, teamSize: 11, ratio: "1:10" }
    ]
  },
  SaaS: {
    x: "SaaS",
    y: [
      { hires: 14, teamSize: 1, ratio: "1:15" },
      { hires: 9, teamSize: 1, ratio: "1:10" },
      { hires: 32, teamSize: 1, ratio: "1:30" },
      { hires: 90, teamSize: 3, ratio: "1:30" },
      { hires: 100, teamSize: 4, ratio: "1:25" },
      { hires: 349, teamSize: 26, ratio: "1:15" },
      { hires: 466, teamSize: 29, ratio: "1:15" }
    ]
  },
  Healthtech: {
    x: "Healthtech",
    y: [
      { hires: 35, teamSize: 1, ratio: "1:35" },
      { hires: 105, teamSize: 5, ratio: "1:20" },
      { hires: 62, teamSize: 5, ratio: "1:10" },
      { hires: 450, teamSize: 8, ratio: "1:55" }
    ]
  },
  "eCommerce/DTC": {
    x: "eCommerce/DTC",
    y: [
      { hires: 24, teamSize: 0, ratio: "-" },
      { hires: 130, teamSize: 5, ratio: "1:25" }
    ]
  },
  Fintech: {
    x: "Fintech",
    y: [
      { hires: 240, teamSize: 8, ratio: "1:30" },
      { hires: 158, teamSize: 23, ratio: "1:5" }
    ]
  },
  Edtech: { x: "Edtech", y: [{ hires: 11, teamSize: 0, ratio: "-" }] }
};
