import React from "react";
import logo from "images/datacult-logo.png";
import teamImg from "images/datacult.png";
import "./Credits.scss";

const Credits = () => (
  <>
    <nav>
      <div className="datacult-logo-container">
        <img src={logo} className="datacult-logo" alt="logo" />
      </div>
    </nav>
    <div className="credits-page">
      <h1>Credits</h1>
      <p className="credits-paragraph">
        This project was brought to you by{" "}
        <a href="https://datacult.com">datacult</a> and friends{" "}
        <span role="img" aria-label="heart">
          ❤️
        </span>
      </p>
      <div>
        <img src={teamImg} alt="team" className="datacult-team" />
      </div>
    </div>
  </>
);

export default Credits;
