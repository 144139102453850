import React from "react";
import Select from "components/select";
import { find, map } from "lodash";
import { ADDITIONAL } from "./data";
import PTOChart from "charts/ptoChart";
import style from "./index.module.scss";
import sharedStyle from "charts/shared.module.scss";

const DATA_MAP = {
  [ADDITIONAL["Seed-A"].name]: ADDITIONAL["Seed-A"],
  [ADDITIONAL["B-C"].name]: ADDITIONAL["B-C"],
  [ADDITIONAL["D+"].name]: ADDITIONAL["D+"]
};

const filterOptions = map(DATA_MAP, (_, key) => ({ label: key, value: key }));
const getDefaultOption = () =>
  find(filterOptions, { value: ADDITIONAL["Seed-A"].name });

const List = ({ data }) => (
  <div className={style.list_container}>
    {data.map(row => (
      <div className={style.list_row} key={row + "ab1"}>
        {row}
      </div>
    ))}
  </div>
);

class AdditionalBenefitsChart extends React.Component {
  state = {
    selectedChart: ADDITIONAL["Seed-A"]
  };

  handleChange = option =>
    this.setState({ selectedChart: DATA_MAP[option.value] });

  render() {
    const {
      selectedChart: {
        name,
        retirementProviders,
        percent401k,
        percentMatch,
        parentalLeave,
        pto_unlimited,
        pto
      }
    } = this.state;

    let pto_days;
    if (this.state.selectedChart.name === "Seed-A") {
      pto_days = <span></span>;
    } else {
      pto_days = (
        <div className={style.stat_container2}>
          <span className={sharedStyle.tool}>
            <div className={style.basic_stat}>
              {parseInt(pto.pto) + " days"}
            </div>
            <span className={sharedStyle.tooltip} style={{ width: `95px` }}>
              {pto.hover_range}
            </span>
          </span>
          <div className={style.basic_stat_text}>
            Avg <b>Time Off</b> for Defined PTO policies. Includes both vacation
            and sick days.
          </div>
        </div>
      );
    }

    return (
      <div className={style.container}>
        <div className={style.filter}>
          {" "}
          <div className={style.select_container}>
            <Select
              options={filterOptions}
              defaultValue={getDefaultOption(name)}
              onChange={this.handleChange}
            />
          </div>
        </div>

        <div className={style.chart_container}>
          <div className={style.row}>
            <div className={style.content}>
              <div className="chart-name-list">Retirement Providers</div>
              <List data={retirementProviders} />
            </div>

            <div className={style.content_pto}>
              <div className={style.stat_container3}>
                <div className={style.basic_stat}>
                  {parseInt(percent401k * 100) + "%"}
                </div>
                <div className={style.basic_stat_text}>
                  Of companies offer a <b>401k Program</b>
                </div>
              </div>
              <div className={style.stat_container2}>
                <div className={style.basic_stat}>
                  {parseInt(percentMatch * 100) + "%"}
                </div>
                <div className={style.basic_stat_text}>
                  Of companies offer a <b>401k Match</b>
                </div>
              </div>
            </div>
          </div>

          <div className={style.row}>
            <div className={style.content}>
              <div className="chart-name-list">Parental Leave</div>
              <p>
                Averages vary by geography according to local laws and market
                conditions. Increasingly paid leave is equally available to all
                employees (though some policies vary).
              </p>
              <p>
                Average is for US companies; international policies and laws
                vary significantly.
              </p>
            </div>

            <div className={style.content}>
              <div className={style.stat_container}>
                <span className={sharedStyle.tool}>
                  <div className={style.basic_stat}>
                    {parseInt(parentalLeave.value) + " weeks"}
                  </div>
                  <span
                    className={sharedStyle.tooltip}
                    style={{ width: `100px` }}
                  >
                    {parentalLeave.hover}
                  </span>
                </span>
                <div className={style.basic_stat_text}>
                  Avg <b>Parental Leave</b>
                </div>
              </div>
            </div>
          </div>

          <div className={style.row}>
            <div className={style.content}>
              <div className="chart-name-list">PTO</div>
              <p>
                Some policies offered additional time off for volunteer work and
                continued learning.
                <br />
                <br />
                In most cases, policies differ for nonexempt and hourly
                employees.
              </p>
            </div>

            <div className={style.content_pto}>
              <div className={style.stat_container3}>
                <div className={style.basic_stat}>
                  {parseInt(pto_unlimited * 100) + "%"}
                </div>
                <div className={style.basic_stat_text}>
                  Of companies offer <b>Unlimited PTO</b>
                </div>
              </div>
              {pto_days}
            </div>
          </div>
          <div className={style.row}>
            <div className={style.content_ptochart}>
              <PTOChart />
            </div>
          </div>
          <div className={style.row}>
            <div className={style.content}>
              <div className="chart-name-list">Other Benefits</div>
              <div className={style.list_container}>
                <div className={style.list_row}>Meal stipends</div>
                <div className={style.list_row}>
                  <span className="hover_text1">
                    Short and long term disability
                    <span className="tooltip">
                      Nearly 100% of companies provide
                    </span>
                  </span>
                </div>
                <div className={style.list_row}>
                  Learning and development stipend
                </div>
                <div className={style.list_row}>Life insurance</div>
                <div className={style.list_row}>Childcare</div>
                <div className={style.list_row}>Commuter benefits</div>
                <div className={style.list_row}>Pet insurance</div>
                <div className={style.list_row}>Work-from-home stipend</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdditionalBenefitsChart;
