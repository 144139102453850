export const HEALTHCARE = {
  "Seed-A": {
    name: "Seed-A",
    peo: ["Justworks", "Gusto"],
    brokers: ["Pipo Saúde", "Gusto"],
    peoVbroker: [
      { order: 0, x: "PEO", y: 7, color: "#6F89CC", label: "78%" },
      { order: 1, x: "Benefits Broker", y: 2, color: "#F8FBFF", label: "22%" }
    ],
    healthcareProviders: [
      "Aetna",
      "Cigna",
      "Blue Cross/Blue Shield",
      "Kaiser Permanente",
      "United Healthcare",
      "Sulamerica",
      "Anthem Blue Cross"
    ],
    additionalHealthcareProviders: [
      "Dental + Vision",
      "Direct Primary Care",
      "Mental Health",
      "Fertility Care",
      "Fitness Stipends"
    ],
    medicalCoverage: {
      employees: { avg: 0.925130890052356, min: 0.75, max: 1 },
      dependents: { avg: 0.738390600554894, min: 0.3, max: 0.9438328769798842 }
    },
    medicalBreakdown: [
      {
        coverage: "Employee Only",
        employee: 15,
        employer: 455,
        employee_hover: "Range $0-$115",
        employee_hover2: "25th percentile $0",
        employee_hover3: "75th percentile $1",
        employer_hover: "Range $1-$975",
        employer_hover2: "25th percentile $140",
        employer_hover3: "75th percentile $625"
      },
      {
        coverage: "Employee + dependents",
        employee: 110,
        employer: 930,
        employee_hover: "Range $1-$295",
        employee_hover2: "25th percentile $80",
        employee_hover3: "75th percentile $90",
        employer_hover: "Range $0-$1,790",
        employer_hover2: "25th percentile $600",
        employer_hover3: "75th percentile $1,195"
      },
      {
        coverage: "Employee + spouse",
        employee: 130,
        employer: 960,
        employee_hover: "Range $1-$335",
        employee_hover2: "25th percentile $60",
        employee_hover3: "75th percentile $165",
        employer_hover: "Range $0-$1,965",
        employer_hover2: "25th percentile $600",
        employer_hover3: "75th percentile $1,195"
      },
      {
        coverage: "Family",
        employee: 215,
        employer: 1345,
        employee_hover: "Range $1-$495",
        employee_hover2: "25th percentile $120",
        employee_hover3: "75th percentile $275",
        employer_hover: "Range $0-$2,780",
        employer_hover2: "25th percentile $650",
        employer_hover3: "75th percentile $1,795"
      }
    ],
    hsa: 0.7142857142857143,
    plansOffered: "2-4"
  },
  "B-C": {
    name: "B-C",
    peo: ["Justworks", "Sequoia One"],
    brokers: ["Nava", "Exude", "JustWorks", "Epic Brokers ", "Sequoia"],
    peoVbroker: [
      { order: 0, x: "PEO", y: 4, color: "#6F89CC", label: "50%" },
      { order: 1, x: "Benefits Broker", y: 4, color: "#F8FBFF", label: "50%" }
    ],
    healthcareProviders: [
      "Aetna",
      "Cigna",
      "Blue Cross/Blue Shield",
      "Kaiser Permanente",
      "United Healthcare",
      "Sulamerica",
      "Anthem Blue Cross"
    ],
    additionalHealthcareProviders: [
      "Dental + Vision",
      "Direct Primary Care",
      "Mental Health",
      "Fertility Care",
      "Fitness Stipends"
    ],
    medicalCoverage: {
      employees: { avg: 0.8408829045469091, min: 0.7756467885326142, max: 1 },
      dependents: {
        avg: 0.6992907611749729,
        min: 0.6521957633178203,
        max: 0.7478080273826717
      }
    },
    medicalBreakdown: [
      {
        coverage: "Employee Only",
        employee: 70,
        employer: 490,
        employee_hover: "Range $0-$120",
        employee_hover2: "25th percentile $35",
        employee_hover3: "75th percentile $105",
        employer_hover: "Range $235-$720",
        employer_hover2: "25th percentile $430",
        employer_hover3: "75th percentile $565"
      },
      {
        coverage: "Employee + dependents",
        employee: 380,
        employer: 825,
        employee_hover: "Range $160-$630",
        employee_hover2: "25th percentile $260",
        employee_hover3: "75th percentile $490",
        employer_hover: "Range $640-$945",
        employer_hover2: "25th percentile $795",
        employer_hover3: "75th percentile $890"
      },
      {
        coverage: "Employee + spouse",
        employee: 460,
        employer: 945,
        employee_hover: "Range $190-$900",
        employee_hover2: "25th percentile $325",
        employee_hover3: "75th percentile $520",
        employer_hover: "Range $440-$1,550",
        employer_hover2: "25th percentile $880",
        employer_hover3: "75th percentile $975"
      },
      {
        coverage: "Family",
        employee: 735,
        employer: 1320,
        employee_hover: "Range $275-$1,665",
        employee_hover2: "25th percentile $530",
        employee_hover3: "75th percentile $720",
        employer_hover: "Range $640-$2,175",
        employer_hover2: "25th percentile $1,195",
        employer_hover3: "75th percentile $1,385"
      }
    ],
    hsa: 1,
    plansOffered: "2-4"
  },
  "D+": {
    name: "D+",
    peo: ["Justworks", "Sequoia One", "TriNet", "Payroll Edge"],
    brokers: [
      "Sequoia",
      "Sequoia Benefits",
      "Gallagher Benefits Services",
      "Lumity and Glennan"
    ],
    peoVbroker: [
      { order: 0, x: "PEO", y: 2, color: "#6F89CC", label: "29%" },
      { order: 1, x: "Benefits Broker", y: 5, color: "#F8FBFF", label: "71%" }
    ],
    healthcareProviders: [
      "Aetna",
      "Cigna",
      "Blue Cross/Blue Shield",
      "Kaiser Permanente",
      "United Healthcare",
      "Sulamerica",
      "Anthem Blue Cross"
    ],
    additionalHealthcareProviders: [
      "Dental + Vision",
      "Direct Primary Care",
      "Mental Health",
      "Fertility Care",
      "Fitness Stipends"
    ],
    medicalCoverage: {
      employees: { avg: 0.9437043372766922, min: 0.8999882666979555, max: 1 },
      dependents: { avg: 0.8643876695340182, min: 0.750136728863754, max: 1 }
    },
    medicalBreakdown: [
      {
        coverage: "Employee Only",
        employee: 35,
        employer: 585,
        employee_hover: "Range $0-$70",
        employee_hover2: "25th percentile $10",
        employee_hover3: "75th percentile $60",
        employer_hover: "Range $490-$750",
        employer_hover2: "25th percentile $505",
        employer_hover3: "75th percentile $625"
      },
      {
        coverage: "Employee + dependents",
        employee: 150,
        employer: 1050,
        employee_hover: "Range $0-$255",
        employee_hover2: "25th percentile $125",
        employee_hover3: "75th percentile $190",
        employer_hover: "Range $765-$1,515",
        employer_hover2: "25th percentile $835",
        employer_hover3: "75th percentile $1,175"
      },
      {
        coverage: "Employee + spouse",
        employee: 175,
        employer: 1225,
        employee_hover: "Range $0-$270",
        employee_hover2: "25th percentile $155",
        employee_hover3: "75th percentile $235",
        employer_hover: "Range $805-$1,710",
        employer_hover2: "25th percentile $1,005",
        employer_hover3: "75th percentile $1,410"
      },
      {
        coverage: "Family",
        employee: 265,
        employer: 1680,
        employee_hover: "Range $0-$380",
        employee_hover2: "25th percentile $230",
        employee_hover3: "75th percentile $365",
        employer_hover: "Range $1,140-$2,240",
        employer_hover2: "25th percentile $1,330",
        employer_hover3: "75th percentile $2,015"
      }
    ],
    hsa: 1,
    plansOffered: "5+"
  }
};
