export const HRSPEND = {
  "Seed-A": {
    name: "Seed-A",
    eoyComp: 0.06666666666666667,
    equityRefresh: 0.5,
    relocationComp: 0.375,
    breakdown: [
      {
        x: "HR Technology",
        y: [5715, 3000, 15000],
        pct: 0.11463013368372976,
        mean: 7905,
        max: 100000,
        hover: "Range $3,000-$15,000",
        hover2: "25th percentile $4,000",
        hover3: "75th percentile $10,000"
      },
      {
        x: "Recruiting Agencies",
        y: [24960, 90000, 30000],
        pct: 0.5941487657566483,
        mean: 48320,
        max: 100000,
        hover: "Range $25,000-$90,000",
        hover2: "25th percentile $27,000",
        hover3: "75th percentile $60,000"
      },
      {
        x: "Company Offsites & Team Events",
        y: [40000, 20000],
        pct: 0.43683165083943293,
        mean: 30000,
        max: 100000,
        hover: "Range $20,000-$40,000",
        hover2: "25th percentile $25,000",
        hover3: "75th percentile $35,000"
      }
    ]
  },
  "B-C": {
    name: "B-C",
    eoyComp: 0.06,
    equityRefresh: 0.6666666666666666,
    relocationComp: 0.5,
    breakdown: [
      {
        x: "HR Technology",
        y: [20000, 260000, 261000, 40000, 200000],
        pct: 0.1275763225116469,
        mean: 156200,
        max: 1000000,
        hover: "Range $20,000-$261,000",
        hover2: "25th percentile $40,000",
        hover3: "75th percentile $260,000"
      },
      {
        x: "Recruiting Agencies",
        y: [300000, 492500, 527269, 650000, 500000],
        pct: 0.471908690819075,
        mean: 493953.8,
        max: 1000000,
        hover: "Range $300,000-$650,000",
        hover2: "25th percentile $493,000",
        hover3: "75th percentile $527,000"
      },
      {
        x: "Company Offsites & Team Events",
        y: [470500, 320000, 271223, 40000, 67700],
        pct: 0.21977383094440367,
        mean: 233884.6,
        max: 1000000,
        hover: "Range $40,000-$471,000",
        hover2: "25th percentile $68,000",
        hover3: "75th percentile $320,000"
      },
      {
        x: "DEI Consultants & Programs",
        y: [50000, 85000, 70000, 0, 20000],
        pct: 0.038346287225414825,
        mean: 45000,
        max: 1000000,
        hover: "Range $0-$85,000",
        hover2: "25th percentile $20,000",
        hover3: "75th percentile $70,000"
      },
      {
        x: "Coaches for Executive Team",
        y: [3600, 52310, 20000, 35000],
        pct: 0.025148573376316503,
        mean: 27727.5,
        max: 1000000,
        hover: "Range $4,000-$52,000",
        hover2: "25th percentile $16,000",
        hover3: "75th percentile $39,000"
      },
      {
        x: "Learning & Development",
        y: [18000, 235000, 75939, 20000, 50000],
        pct: 0.06352579374628967,
        mean: 79787.8,
        max: 1000000,
        hover: "Range $18,000-$235,000",
        hover2: "25th percentile $20,000",
        hover3: "75th percentile $76,000"
      }
    ]
  },
  "D+": {
    name: "D+",
    eoyComp: 0.03166666666666666,
    equityRefresh: 1,
    relocationComp: 0.25,
    breakdown: [
      {
        x: "HR Technology",
        y: [80000, 50000, 100000, 270000, 220000],
        pct: 0.04533262740209145,
        mean: 144000,
        max: 2500000,
        hover: "Range $50,000-$270,000",
        hover2: "25th percentile $80,000",
        hover3: "75th percentile $220,000"
      },
      {
        x: "Recruiting Agencies",
        y: [2400000, 84000, 500000, 643000, 650000],
        pct: 0.2208545636165055,
        mean: 855400,
        max: 2500000,
        hover: "Range $84,000-$2,400,000",
        hover2: "25th percentile $500,000",
        hover3: "75th percentile $650,000"
      },
      {
        x: "Company Offsites & Team Events",
        y: [165000, 55000, 300000, 100000],
        pct: 0.07519656212577085,
        mean: 155000,
        max: 2500000,
        hover: "Range $55,000-$300,000",
        hover2: "25th percentile $89,000",
        hover3: "75th percentile $199,000"
      },
      {
        x: "DEI Consultants & Programs",
        y: [219000, 200000, 220000],
        pct: 0.05990601071346413,
        mean: 213000,
        max: 2500000,
        hover: "Range $200,000-$220,000",
        hover2: "25th percentile $210,000",
        hover3: "75th percentile $220,000"
      },
      {
        x: "Coaches for Executive Team",
        y: [100000, 360000],
        pct: 0.04734299516908212,
        mean: 230000,
        max: 2500000,
        hover: "Range $100,000-$360,000",
        hover2: "25th percentile $165,000",
        hover3: "75th percentile $295,000"
      },
      {
        x: "Learning & Development",
        y: [415000, 425000, 100000, 220000, 550000],
        pct: 0.1932689774016579,
        mean: 342000,
        max: 2500000,
        hover: "Range $100,000-$550,000",
        hover2: "25th percentile $220,000",
        hover3: "75th percentile $425,000"
      }
    ]
  }
};
