export const HEADCOUNT = {
  Marketplace: {
    x: "Marketplace",
    y: [
      {
        type: "Technical",
        roles: [
          { x: "Engineering", y: 23, count: 53, hover: "Range 21-25%" },
          { x: "Product", y: 6, count: 14, hover: "Range 4-8%" },
          { x: "Design", y: 1, count: 3, hover: "Range 0-2%" },
          { x: "Data Science", y: 5, count: 11, hover: "Range 3-7%" }
        ],
        pct_cat: 35
      },
      {
        type: "GTM",
        roles: [
          { x: "Sales", y: 26, count: 64.5, hover: "Range 11-41%" },
          { x: "Marketing", y: 7, count: 17, hover: "Range 5-9%" }
        ],
        pct_cat: 33
      },
      {
        type: "People",
        roles: [
          { x: "HR/People Ops", y: 2, count: 5.5, hover: "Range 2-3%" },
          { x: "Recruiting", y: 4, count: 7.5, hover: "Range 2-5%" }
        ],
        pct_cat: 6
      },
      {
        type: "Business",
        roles: [
          { x: "Ops", y: 5, count: 11.5, hover: "Range 3-8%" },
          { x: "Finance/Accounting", y: 9, count: 19, hover: "Range 3-15%" },
          { x: "Other G&A", y: 2, count: 4.5, hover: "Range 0-4%" }
        ],
        pct_cat: 16
      },
      {
        type: "Customer",
        roles: [
          { x: "Customer Success", y: 4, count: 10, hover: "Range 3-5%" },
          { x: "Customer Support", y: 3, count: 6, hover: "Range 2-3%" }
        ],
        pct_cat: 7
      }
    ],
    ratio: "18:5:1"
  },
  SaaS: {
    x: "SaaS",
    y: [
      {
        type: "Technical",
        roles: [
          { x: "Engineering", y: 36, count: 50.9, hover: "Range 16-56%" },
          { x: "Product", y: 8, count: 12.4, hover: "Range 0-22%" },
          { x: "Design", y: 6, count: 4.5, hover: "Range 0-19%" },
          { x: "Data Science", y: 1, count: 2.5, hover: "Range 0-4%" }
        ],
        pct_cat: 51
      },
      {
        type: "GTM",
        roles: [
          { x: "Sales", y: 12, count: 30.7, hover: "Range 0-26%" },
          { x: "Marketing", y: 7, count: 15.6, hover: "Range 0-15%" }
        ],
        pct_cat: 19
      },
      {
        type: "People",
        roles: [
          { x: "HR/People Ops", y: 3, count: 3.9, hover: "Range 1-5%" },
          { x: "Recruiting", y: 4, count: 7.8, hover: "Range 3-6%" }
        ],
        pct_cat: 7
      },
      {
        type: "Business",
        roles: [
          { x: "Ops", y: 5, count: 2.3, hover: "Range 0-26%" },
          { x: "Finance/Accounting", y: 3, count: 6.2, hover: "Range 0-5%" },
          { x: "Other G&A", y: 5, count: 11.2, hover: "Range 0-17%" }
        ],
        pct_cat: 13
      },
      {
        type: "Customer",
        roles: [
          { x: "Customer Success", y: 12, count: 27.1, hover: "Range 5-23%" },
          { x: "Customer Support", y: 6, count: 11.4, hover: "Range 0-13%" }
        ],
        pct_cat: 18
      }
    ],
    ratio: "11:3:1"
  },
  Healthtech: {
    x: "Healthtech",
    y: [
      {
        type: "Technical",
        roles: [
          {
            x: "Engineering",
            y: 26,
            count: 33.166666666666664,
            hover: "Range 4-67%"
          },
          {
            x: "Product",
            y: 6,
            count: 10.833333333333334,
            hover: "Range 2-9%"
          },
          { x: "Design", y: 2, count: 3.8333333333333335, hover: "Range 1-3%" },
          {
            x: "Data Science",
            y: 4,
            count: 6.666666666666667,
            hover: "Range 2-6%"
          }
        ],
        pct_cat: 38
      },
      {
        type: "GTM",
        roles: [
          { x: "Sales", y: 6, count: 14.833333333333334, hover: "Range 3-9%" },
          {
            x: "Marketing",
            y: 3,
            count: 3.6666666666666665,
            hover: "Range 2-4%"
          }
        ],
        pct_cat: 9
      },
      {
        type: "People",
        roles: [
          {
            x: "HR/People Ops",
            y: 3,
            count: 5.833333333333333,
            hover: "Range 2-4%"
          },
          {
            x: "Recruiting",
            y: 3,
            count: 6.833333333333333,
            hover: "Range 2-5%"
          }
        ],
        pct_cat: 6
      },
      {
        type: "Business",
        roles: [
          { x: "Ops", y: 15, count: 37.833333333333336, hover: "Range 5-26%" },
          {
            x: "Finance/Accounting",
            y: 3,
            count: 7.166666666666667,
            hover: "Range 1-6%"
          },
          {
            x: "Other G&A",
            y: 4,
            count: 6.166666666666667,
            hover: "Range 0-13%"
          }
        ],
        pct_cat: 22
      },
      {
        type: "Customer",
        roles: [
          {
            x: "Customer Success",
            y: 3,
            count: 2.6666666666666665,
            hover: "Range 1-5%"
          },
          {
            x: "Customer Support",
            y: 10,
            count: 21.833333333333332,
            hover: "Range 0-28%"
          }
        ],
        pct_cat: 13
      }
    ],
    ratio: "9:3:1"
  },
  Fintech: {
    x: "Fintech",
    y: [
      {
        type: "Technical",
        roles: [
          { x: "Engineering", y: 28, count: 22.2, hover: "Range 25-31%" },
          { x: "Product", y: 12, count: 9, hover: "Range 6-17%" },
          { x: "Design", y: 5, count: 4.8, hover: "Range 5-5%" },
          { x: "Data Science", y: 3, count: 4, hover: "Range 3-3%" }
        ],
        pct_cat: 48
      },
      {
        type: "GTM",
        roles: [
          { x: "Sales", y: 12, count: 13, hover: "Range 7-17%" },
          { x: "Marketing", y: 6, count: 13, hover: "Range 3-8%" }
        ],
        pct_cat: 18
      },
      {
        type: "People",
        roles: [
          { x: "HR/People Ops", y: 4, count: 4.8, hover: "Range 3-4%" },
          { x: "Recruiting", y: 2, count: 6.2, hover: "Range 2-2%" }
        ],
        pct_cat: 6
      },
      {
        type: "Business",
        roles: [
          { x: "Ops", y: 4, count: 4.6, hover: "Range 3-4%" },
          { x: "Finance/Accounting", y: 3, count: 6.2, hover: "Range 2-3%" },
          { x: "Other G&A", y: null, count: 0, hover: "Range NaN-NaN%" }
        ],
        pct_cat: 7
      },
      {
        type: "Customer",
        roles: [
          { x: "Customer Success", y: 8, count: 10.6, hover: "Range 3-13%" },
          { x: "Customer Support", y: 6, count: 8, hover: "Range 3-8%" }
        ],
        pct_cat: 14
      }
    ],
    ratio: "5:2:1"
  },
  "eCommerce/DTC": {
    x: "eCommerce/DTC",
    y: [
      {
        type: "Technical",
        roles: [
          { x: "Engineering", y: 11, count: 25.5, hover: "Range 6-15%" },
          { x: "Product", y: 3, count: 4.5, hover: "Range 2-4%" },
          { x: "Design", y: 4, count: 4.5, hover: "Range 1-7%" },
          { x: "Data Science", y: 2, count: 5.5, hover: "Range 0-4%" }
        ],
        pct_cat: 20
      },
      {
        type: "GTM",
        roles: [
          { x: "Sales", y: 4, count: 8, hover: "Range 4-4%" },
          { x: "Marketing", y: 13, count: 31, hover: "Range 7-19%" }
        ],
        pct_cat: 17
      },
      {
        type: "People",
        roles: [
          { x: "HR/People Ops", y: 3, count: 7, hover: "Range 3-4%" },
          { x: "Recruiting", y: 1, count: 2.5, hover: "Range 0-2%" }
        ],
        pct_cat: 4
      },
      {
        type: "Business",
        roles: [
          { x: "Ops", y: 9, count: 25, hover: "Range 3-16%" },
          { x: "Finance/Accounting", y: 8, count: 14.5, hover: "Range 7-8%" },
          { x: "Other G&A", y: 12, count: 12, hover: "Range 3-21%" }
        ],
        pct_cat: 29
      },
      {
        type: "Customer",
        roles: [
          { x: "Customer Success", y: 0, count: 0, hover: "Range 0-0%" },
          { x: "Customer Support", y: 8, count: 15, hover: "Range 8-9%" }
        ],
        pct_cat: 8
      }
    ],
    ratio: "6:1:1"
  },
  Edtech: {
    x: "Edtech",
    y: [
      {
        type: "Technical",
        roles: [
          { x: "Engineering", y: 11, count: 2, hover: "Range 11-11%" },
          { x: "Product", y: 17, count: 3, hover: "Range 17-17%" },
          { x: "Design", y: 0, count: 0, hover: "Range 0-0%" },
          { x: "Data Science", y: 17, count: 3, hover: "Range 17-17%" }
        ],
        pct_cat: 45
      },
      {
        type: "GTM",
        roles: [
          { x: "Sales", y: 28, count: 5, hover: "Range 28-28%" },
          { x: "Marketing", y: 6, count: 1, hover: "Range 6-6%" }
        ],
        pct_cat: 34
      },
      {
        type: "People",
        roles: [
          { x: "HR/People Ops", y: 0, count: 0, hover: "Range 0-0%" },
          { x: "Recruiting", y: 0, count: 0, hover: "Range 0-0%" }
        ],
        pct_cat: 0
      },
      {
        type: "Business",
        roles: [
          { x: "Ops", y: 0, count: 0, hover: "Range 0-0%" },
          { x: "Finance/Accounting", y: 6, count: 1, hover: "Range 6-6%" },
          { x: "Other G&A", y: 0, count: 0, hover: "Range 0-0%" }
        ],
        pct_cat: 6
      },
      {
        type: "Customer",
        roles: [
          { x: "Customer Success", y: 0, count: 0, hover: "Range 0-0%" },
          { x: "Customer Support", y: 0, count: 0, hover: "Range 0-0%" }
        ],
        pct_cat: 0
      }
    ],
    ratio: "Infinity:Infinity:NaN"
  }
};
